import { Container } from "react-bootstrap";

export const Home: React.FC = () => {
  return (
    <Container>
      <div className="mt-5">
        <h1>Welcome to the Senti Order Tracker</h1>
        <p className="lead">
          This website can be used to track the purchasing, receiving, fabrication and shipping progress of any Senti
          sales order.
        </p>
        <p>To get started, select a sales order from the menu at the top of the page.</p>
      </div>
    </Container>
  );
};
