import { SupabaseSelectResult } from "lib/supabase";
import { Dinero, dineroForCurrencyFromFloatingPoint } from "utils/dinero-util";
import { CostTaxBreakdown } from "./cost-tax-breakdown";
import { CADCurrencyConverter } from "./currency";
import {
  vendorPricingInformationSelect,
  VendorWithPricingInformation,
  VendorSelectRelatedTables,
  getCurrencyCodeForVendor,
} from "./vendor";

export interface Item extends ItemSelectResult {
  preferredPurchaseCost: CostTaxBreakdown;
}

export type ItemSelectResult = SupabaseSelectResult<"Item", typeof itemSelect, ItemSelectRelatedTables>;

export type ItemSelectRelatedTables = "Vendor" | "VendorItemCatalog" | VendorSelectRelatedTables;

export const itemSelect = `
  id,
  name,
  type,
  description,
  purchaseCost,
  preferredVendor:Vendor!vendorId(${vendorPricingInformationSelect}),
  purchaseTaxCode:TaxCode!purchaseTaxCodeId(*),
  catalogEntries:VendorItemCatalog(
    *,
    vendor:Vendor!vendorId(${vendorPricingInformationSelect})
  )
`;

export function processItem(item: ItemSelectResult, CADCurrencyConverter: CADCurrencyConverter): Item {
  const preferredPurchaseCost = getPurchaseCostForItemAndVendor(item, item.preferredVendor, CADCurrencyConverter);
  return {
    ...item,
    preferredPurchaseCost,
  };
}

/**
 * Get the purchase cost for a specific vendor and item combination.
 *
 * See {@link getPreferredPurchaseSubtotalCad} for details on how the cost is calculated.
 *
 * The tax code from the vendor is used. If there is no tax code on the vendor, it is an error and we don't fall back
 * to the tax code on the item.
 */
export function getPurchaseCostForItemAndVendor(
  item: ItemSelectResult,
  vendor: VendorWithPricingInformation | null,
  CADCurrencyConverter: CADCurrencyConverter,
): CostTaxBreakdown {
  // Get the subtotal for the item, return an error on failure
  const subtotalResult = getPreferredPurchaseSubtotalCad(item, vendor, CADCurrencyConverter);
  if ("error" in subtotalResult) return CostTaxBreakdown.withError(subtotalResult.error);

  // Get the tax code
  const taxCode = vendor?.taxCode ?? null;

  // Compute the full cost breakdown
  return new CostTaxBreakdown({ subtotal: subtotalResult.amount, taxCode });
}

/**
 * Get the purchase cost subtotal for a specific item and vendor combination. The vendor is optional.
 *
 * If a vendor is specified, the cost comes from the associated vendor-item catalog entry, if any, falling
 * back to the item purchase cost.
 *
 * If no vendor is specified, but a single vendor-item catalog entry exists, the cost comes from that entry.
 *
 * Otherwise, an error is returned and the cost returned is a best-guess that should not be used.
 */
function getPreferredPurchaseSubtotalCad(
  item: ItemSelectResult,
  vendor: VendorWithPricingInformation | null,
  CADCurrencyConverter: CADCurrencyConverter,
): { amount: Dinero } | { error: string } {
  if (vendor) {
    // If the item has a preferred vendor, use the catalog entry for that vendor if it exists, fall back to the item's purchase cost
    const catalogEntry = item.catalogEntries.find((x) => x.vendorId === vendor.id);
    const vendorCurrencyCode = getCurrencyCodeForVendor(vendor);
    const purchaseCost = dineroForCurrencyFromFloatingPoint(
      catalogEntry ? catalogEntry.price : item.purchaseCost,
      vendorCurrencyCode,
    );
    return { amount: CADCurrencyConverter(purchaseCost) };
  } else if (item.catalogEntries.length > 1) {
    // Return an error if there were multiple catalog entries to choose from
    return { error: `Item '${item.name}' has multiple vendor-item catalog entries and no preferred vendor` };
  } else if (item.catalogEntries.length) {
    // If the item has no preferred vendor, but a single catalog entry, use that entry
    const catalogEntry = item.catalogEntries[0]!;
    const vendorCurrencyCode = getCurrencyCodeForVendor(catalogEntry.vendor);
    const purchaseCost = dineroForCurrencyFromFloatingPoint(catalogEntry.price, vendorCurrencyCode);
    return { amount: CADCurrencyConverter(purchaseCost) };
  } else {
    // Otherwise there are no catalog entries or preferred vendor, return an error
    return { error: `Item '${item.name}' has no preferred vendor or vendor-item catalog entries` };
  }
}
