import {
  CustomFieldDefinitionMap,
  CustomFieldValue,
  DefinitionKeyForDataType,
  CustomFieldValueMap,
  CustomFieldValueForDataType,
} from "types/sos-types";
import { Tables } from "types/supabase-types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SosCustomFields {
  /**
   * This object holds the metadata for each custom field. It is used by utility methods to perform reading/writing of
   * custom field values with static typing.
   *
   * TODO: This is basically a copy of the associated type definition. It would be more DRY to instead automatically
   * derive the `CustomFieldDefinitionMap` type definition by declaring this object as `const` and using the `typeof`
   * operator. The current implementation is a holdover from before we switched to ParcelJS bundling.
   */
  const definitions: CustomFieldDefinitionMap = {
    manufacturer: { id: 2, dataType: "Text", name: "Manufacturer", showOn: ["Items"] },
    receivingInspectionFlag: { id: 3, dataType: "Boolean", name: "Receiving Inspection Flag", showOn: ["Item Receipts", "Items", "Purchase Orders"] }, // prettier-ignore
    kanban: { id: 4, dataType: "Boolean", name: "Kanban", showOn: ["Items"] },
    vendorReceiptNum: { id: 5, dataType: "Text", name: "Vendor Receipt Num", showOn: ["Item Receipts"] },
    pressureSystem: { id: 7, dataType: "Boolean", name: "Pressure System", showOn: ["Jobs", "Purchase Orders"] },
    createdBy: { id: 9, dataType: "Text", name: "Created By", showOn: ["Builds", "Item Receipts", "Purchase Orders", "Shipments", "Work Orders"] }, // prettier-ignore
    approvedBy: { id: 10, dataType: "Text", name: "Approved By", showOn: ["Purchase Orders"] },
    approvedOn: { id: 11, dataType: "Date", name: "Approved On", showOn: ["Purchase Orders"] },
    inspectionInstructions: { id: 13, dataType: "Text", name: "Inspection Instructions", showOn: ["Items"] },
    electricalDesigner: { id: 15, dataType: "Text", name: "Electrical Designer", showOn: ["Jobs"] },
    mechanicalDesigner: { id: 16, dataType: "Text", name: "Mechanical Designer", showOn: ["Jobs"] },
    model: { id: 18, dataType: "Text", name: "Model", showOn: ["Jobs"] },
    saleType: { id: 19, dataType: "List", name: "Sale Type", showOn: ["Jobs"], listValues: ["Equipment", "Technical Services", "Other", "N/A"] }, // prettier-ignore
    brand: { id: 20, dataType: "List", name: "Brand", showOn: ["Estimates", "Jobs"], listValues: ["SNTI", "GNM", "YS"] }, // prettier-ignore
    pressureCertification: { id: 21, dataType: "List", name: "Pressure Certification", showOn: ["Jobs"], listValues: ["ASME", "CRN"] }, // prettier-ignore
    dateOpened: { id: 22, dataType: "Date", name: "Date Opened", showOn: ["Jobs"] },
    dateClosed: { id: 23, dataType: "Date", name: "Date Closed", showOn: ["Jobs"] },
    orderName: { id: 24, dataType: "Text", name: "Order Name", showOn: ["Jobs"] },
    solidWorksFileName: { id: 25, dataType: "Text", name: "SolidWorks File Name", showOn: ["Items"] },
    manufacturerPartNumber: { id: 26, dataType: "Text", name: "Manufacturer Part Number", showOn: ["Items"] },
    hasCrn: { id: 27, dataType: "Boolean", name: "Has CRN", showOn: ["Items"] },
    evolvedOpportunityNumber: { id: 28, dataType: "Number", name: "Evolved Opportunity Number", showOn: ["Estimates", "Jobs"] }, // prettier-ignore
    dateIssued: { id: 29, dataType: "Date", name: "Date Issued", showOn: ["Purchase Orders"] },
    dateConfirmed: { id: 30, dataType: "Date", name: "Date Confirmed By Vendor", showOn: ["Purchase Orders"] },
    serviceEstimateOpportunityNumber: { id: 31, dataType: "Number", name: "Service Estimate Opportunity Number", showOn: ["Jobs"] }, // prettier-ignore
  };

  /** Any entity that has an array of custom field values */
  export type CustomFieldEntity = SosCustomFieldEntity | SupabaseCustomFieldEntity;

  export interface SosCustomFieldEntity {
    customFields: CustomFieldValue[] | null;
  }

  export interface SupabaseCustomFieldEntity {
    customFieldValues: Omit<Tables<"JobCustomFieldValue">, "jobId">[] | null;
  }

  export function getStringValue(
    entity: CustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Text" | "TextArea">,
  ): string | null {
    return getRawValue(entity, fieldKey);
  }

  export function getBoolValue(
    entity: CustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Boolean">,
  ): boolean | null {
    const rawValue = getRawValue(entity, fieldKey);
    if (!rawValue) return null;
    return rawValue === "true";
  }

  export function getNumberValue(
    entity: CustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Number" | "Money">,
  ): number | null {
    const rawValue = getRawValue(entity, fieldKey);
    if (!rawValue) return null;
    return parseFloat(rawValue);
  }

  export function getListValue<TFieldKey extends DefinitionKeyForDataType<"List">>(
    entity: CustomFieldEntity,
    fieldKey: TFieldKey,
  ): CustomFieldValueMap[TFieldKey]["value"] {
    return getRawValue(entity, fieldKey);
  }

  /** Regex for a date in the format dd/mm/yyyy */
  const customFieldDateFormatRegex = /[0-3]?[0-9]\/[01]?[0-9]\/20[0-9]{2}/;

  /**
   * NOTE: SOS stores the date custom field values according to the date format selected in the user and company
   * settings. We have decided as a company to make sure everyone has their settings set to the format dd/mm/yyyy,
   * which is the format expected here.
   *
   * NOTE: This function parses the value so that the date object returned has the time set to noon on the specified day
   * in Senti's local timezone (ET). By setting the time to noon, we can avoid some errors that may occur due to shifting
   * time zones, DST, etc. We don't actually care about the time portion of this date, so setting it to noon, gives the
   * highest chance of the date portion not being inadvertently changed.
   */
  export function getDateValue(entity: CustomFieldEntity, fieldKey: DefinitionKeyForDataType<"Date">): Date | null {
    // Get the custom field value and validate its format
    const rawValue = getRawValue(entity, fieldKey);
    if (!rawValue) return null;
    if (!customFieldDateFormatRegex.exec(rawValue)) {
      const fieldDefinition = definitions[fieldKey];
      throw new Error(
        `Expected a date in format dd/mm/yyyy for custom field value '${fieldDefinition.name}', but got '${rawValue}'`,
      );
    }

    // Validate each segment of the date
    const [day, month, year] = rawValue.split("/").map((x) => parseInt(x)) as [number, number, number];
    if (month < 1 || month > 12)
      throw new Error(
        `Invalid month value '${month.toFixed()}' for custom field date value (dd/mm/yyyy): '${rawValue}'`,
      );
    if (day < 1 || day > 31)
      throw new Error(`Invalid date value '${day.toFixed()}' for custom field date value (dd/mm/yyyy): '${rawValue}'`);

    // Get the timezone offset for the parsed date without relying on the timezone of the local computer
    // See for details: https://stackoverflow.com/a/77693985/1988326
    const tzname = "America/Toronto";
    const isoString = `${year.toFixed()}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T12:00:00.000`;
    const longOffsetFormatter = new Intl.DateTimeFormat("en-US", { timeZone: tzname, timeZoneName: "longOffset" });
    const longOffsetString = longOffsetFormatter.format(new Date(isoString)); // This will give something like '2/28/2013, GMT-05:00'
    const gmtOffset = (longOffsetString.split("GMT") as [string, string])[1]; // This will give something like '-05:00'

    // Return a new date object with the timezone offset
    return new Date(isoString + gmtOffset);
  }

  const getRawValue = <TFieldKey extends keyof typeof definitions>(
    entity: CustomFieldEntity,
    fieldKey: TFieldKey,
  ): CustomFieldValueMap[TFieldKey]["value"] => {
    // Find the existing value entry
    const fieldDefinition = definitions[fieldKey];
    const customFieldValues = "customFields" in entity ? entity.customFields : entity.customFieldValues;
    const entry = customFieldValues?.find((customFieldValue) => {
      const definitionId = "id" in customFieldValue ? customFieldValue.id : customFieldValue.customFieldDefinitionId;
      return definitionId === fieldDefinition.id;
    });

    // Return null if the value doesn't exist, otherwise return the value
    if (!entry) return null;
    return entry.value;
  };

  export function setStringValue(
    entity: SosCustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Text" | "TextArea">,
    value: string | null,
  ) {
    setRawValue(entity, fieldKey, value);
  }

  export function setBoolValue(
    entity: SosCustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Boolean">,
    value: boolean | null,
  ) {
    const rawValue = formatBoolValue(value);
    setRawValue(entity, fieldKey, rawValue);
  }

  export function setNumberValue(
    entity: SosCustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Number" | "Money">,
    value: number | null,
  ) {
    const rawValue = formatNumberValue(value);
    setRawValue(entity, fieldKey, rawValue);
  }

  export function setDateValue(
    entity: SosCustomFieldEntity,
    fieldKey: DefinitionKeyForDataType<"Date">,
    value: Date | null,
  ) {
    const rawValue = formatDateCustomFieldValue(value);
    setRawValue(entity, fieldKey, rawValue);
  }

  export function setListValue<TFieldKey extends DefinitionKeyForDataType<"List">>(
    entity: SosCustomFieldEntity,
    fieldKey: TFieldKey,
    value: CustomFieldValueMap[TFieldKey]["value"],
  ) {
    setRawValue(entity, fieldKey, value);
  }

  const setRawValue = <TFieldKey extends keyof typeof definitions>(
    entity: SosCustomFieldEntity,
    fieldKey: TFieldKey,
    rawValue: CustomFieldValueMap[TFieldKey]["value"],
  ) => {
    // Set the custom field value
    const fieldDefinition = definitions[fieldKey];
    const { id, name, dataType } = fieldDefinition;
    const customFieldEntries = (entity.customFields ||= []);
    const existingEntry = customFieldEntries.find((customFieldValue) => customFieldValue.id === id);
    if (existingEntry) {
      existingEntry.value = rawValue;
    } else {
      customFieldEntries.push({ id, name, dataType, value: rawValue } as CustomFieldValue);
    }
  };

  export function buildStringValue<TFieldKey extends DefinitionKeyForDataType<"Text" | "TextArea">>(
    fieldKey: TFieldKey,
    value: string | null,
  ): CustomFieldValueMap[TFieldKey] {
    return buildRawValue(fieldKey, value);
  }

  export function buildBoolValue<TFieldKey extends DefinitionKeyForDataType<"Boolean">>(
    fieldKey: TFieldKey,
    value: boolean | null,
  ): CustomFieldValueMap[TFieldKey] {
    const rawValue = formatBoolValue(value);
    return buildRawValue(fieldKey, rawValue);
  }

  export function buildNumberValue<TFieldKey extends DefinitionKeyForDataType<"Number" | "Money">>(
    fieldKey: TFieldKey,
    value: number | null,
  ): CustomFieldValueMap[TFieldKey] {
    const rawValue = formatNumberValue(value);
    return buildRawValue(fieldKey, rawValue);
  }

  export function buildDateValue<TFieldKey extends DefinitionKeyForDataType<"Date">>(
    fieldKey: TFieldKey,
    value: Date | null,
  ): CustomFieldValueMap[TFieldKey] {
    const rawValue = formatDateCustomFieldValue(value);
    return buildRawValue(fieldKey, rawValue);
  }

  export function buildListValue<TFieldKey extends DefinitionKeyForDataType<"List">>(
    fieldKey: TFieldKey,
    value: CustomFieldValueMap[TFieldKey]["value"],
  ): CustomFieldValueMap[TFieldKey] {
    return buildRawValue(fieldKey, value);
  }

  const buildRawValue = <TFieldKey extends keyof typeof definitions>(
    fieldKey: TFieldKey,
    value: CustomFieldValueMap[TFieldKey]["value"],
  ): CustomFieldValueMap[TFieldKey] => {
    const fieldDefinition = definitions[fieldKey];
    return {
      id: fieldDefinition.id,
      name: fieldDefinition.name,
      dataType: fieldDefinition.dataType,
      value,
    } as CustomFieldValueMap[TFieldKey];
  };

  /** Format the provided number value in the format used for number custom field values by the SOS API. */
  const formatNumberValue = (value: number | null): CustomFieldValueForDataType<"Number" | "Money"> => {
    return value === null ? null : value.toString();
  };

  /** Format the provided boolean value in the format used for boolean custom field values by the SOS API. */
  const formatBoolValue = (value: boolean | null): CustomFieldValueForDataType<"Boolean"> => {
    return (
      value === null ? null
      : value ? "true"
      : "false"
    );
  };

  /**
   * Format the provided date in the format used for date custom field values is the SOS web UI (dd/mm/yyyy).
   *
   * NOTE: This format is determined by the date format selected in the user and company settings on the SOS website. We
   * have decided as a company to make sure everyone has this format selected.
   */
  const formatDateCustomFieldValue = (date: Date | null): CustomFieldValueForDataType<"Date"> => {
    if (date === null) return null;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toFixed()}/${month.toFixed()}/${year.toFixed()}`;
  };
}
