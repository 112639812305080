import { Dinero, dineroCADZero, multiplyScaled } from "utils/dinero-util";
import { TaxBreakdown, TaxRateScale, TaxCode } from "./tax-breakdown";
import { add, allocate, subtract } from "dinero.js";

export type ConstructorOptions =
  | { errors: string[] }
  | { subtotal: Dinero; taxCode: TaxCode | null }
  | { total: Dinero; taxCode: TaxCode | null }
  | { subtotal: Dinero; taxes: TaxBreakdown };

/**
 * This class represents the breakdown of a cost into a subtotal and taxes, with tracking for the amount of each tax code applied.
 */
export class CostTaxBreakdown {
  /** Static instance for a cost of $0.00 with no taxed applied */
  public static ZERO = new CostTaxBreakdown({ subtotal: dineroCADZero, taxes: TaxBreakdown.EMPTY });

  public static withError(error: string): CostTaxBreakdown {
    return new CostTaxBreakdown({ errors: [error] });
  }

  public readonly subtotal: Dinero;
  public readonly taxes: TaxBreakdown;
  public readonly tax: Dinero;
  public readonly total: Dinero;

  /**
   * Any errors that occurred while creating the cost breakdown. If this array is empty, the cost breakdown is valid.
   * If this array has one or more elements, the cost breakdown is invalid and the cost values should not be used.
   */
  public readonly errors: string[];

  constructor(options: ConstructorOptions) {
    if ("errors" in options) {
      this.errors = options.errors;
      this.subtotal = dineroCADZero;
      this.taxes = TaxBreakdown.EMPTY;
      this.total = dineroCADZero;
      this.tax = dineroCADZero;
      return;
    } else {
      this.errors = [];
    }

    if ("taxes" in options) {
      this.subtotal = options.subtotal;
      this.taxes = options.taxes;
      this.total = add(options.subtotal, options.taxes.total);
    } else if (options.taxCode === null) {
      this.errors.push("No tax code provided");
      this.subtotal = "subtotal" in options ? options.subtotal : options.total;
      this.taxes = TaxBreakdown.EMPTY;
      this.total = this.subtotal;
    } else {
      const taxCode = options.taxCode;
      const taxRate = taxCode.purchaseTaxRate / 100;

      if ("total" in options) {
        this.total = options.total;
        this.subtotal = multiplyScaled(this.total, { amount: 1 / (1 + taxRate), scale: TaxRateScale });
        const tax = subtract(this.total, this.subtotal);
        this.taxes = new TaxBreakdown(new Map([[taxCode, tax]]));
      } else {
        this.subtotal = options.subtotal;
        const tax = multiplyScaled(options.subtotal, { amount: taxRate, scale: TaxRateScale });
        this.total = add(this.subtotal, tax);
        this.taxes = new TaxBreakdown(new Map([[taxCode, tax]]));
      }
    }

    this.tax = this.taxes.total;
  }

  public add(other: CostTaxBreakdown): CostTaxBreakdown {
    const errors = this.errors.concat(other.errors);
    if (errors.length) return new CostTaxBreakdown({ errors });

    return new CostTaxBreakdown({
      subtotal: add(this.subtotal, other.subtotal),
      taxes: this.taxes.add(other.taxes),
    });
  }

  /**
   * See: https://v2.dinerojs.com/docs/api/mutations/multiply
   */
  public multiply(amount: number, scale: number): CostTaxBreakdown {
    if (this.errors.length) return this;

    return new CostTaxBreakdown({
      subtotal: multiplyScaled(this.subtotal, { amount, scale }),
      taxes: this.taxes.multiply(amount),
    });
  }

  /**
   * See: https://v2.dinerojs.com/docs/api/mutations/allocate
   */
  public allocate(ratios: number[]): CostTaxBreakdown[] {
    if (this.errors.length) return ratios.map(() => this);

    const allocatedSubtotals = allocate(this.subtotal, ratios);
    const allocatedTaxes = this.taxes.allocate(ratios);
    return allocatedSubtotals.map(
      (subtotal, i) =>
        new CostTaxBreakdown({
          subtotal,
          taxes: allocatedTaxes[i]!,
        }),
    );
  }
}
