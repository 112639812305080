import { useContext, useEffect } from "react";

import { supabase } from "lib/supabase";
import { SessionContext } from "stores/SessionContext";

// Callback method to handle Google Sign-In responses
// TODO: Create a nonce for Google authentication. See: https://supabase.com/docs/guides/auth/social-login/auth-google#google-pre-built
async function handleSignInWithGoogle(googleAuthResponse: google.accounts.id.CredentialResponse) {
  // Use the token returned from Google to authenticate with Supabase
  // NOTE: This will raise the `supabase.auth.onAuthStateChange` event to any listeners (i.e. the AuthNavStatus component)
  const supabaseAuthResponse = await supabase.auth.signInWithIdToken({
    provider: "google",
    token: googleAuthResponse.credential,
  });

  // Throw an error if the authentication request failed
  if (supabaseAuthResponse.error) throw supabaseAuthResponse.error;
}

// Initialize the "Sign In With Google JavaScript API"
// This just needs to happen once on page load
// See: https://developers.google.com/identity/gsi/web/reference/js-reference
google.accounts.id.initialize({
  client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
  context: "signin",
  ux_mode: "popup",
  callback: (response) => void handleSignInWithGoogle(response),
  auto_select: true,
  itp_support: true,
  // use_fedcm_for_prompt: true, // TODO: Enable this
});

/**
 * This component will render the Google Sign-In button and initialize the one-tap sign in process (if necessary) when mounted
 */
export const GoogleSignInButton: React.FC = () => {
  const { loading, session } = useContext(SessionContext);

  // Render the Google Sign-In button
  useEffect(() => {
    const parent = document.getElementById("google-sign-in-container");
    if (!parent) throw new Error("Google Sign-In container not found");
    google.accounts.id.renderButton(parent, {
      type: "standard",
      shape: "rectangular",
      theme: "outline",
      text: "signin_with",
      size: "large",
      logo_alignment: "left",
    });
  }, []);

  // If the user is not already signed in, initialize the one-tap sign in process
  useEffect(() => {
    // Do nothing if the session is already loading, or if the user is already signed in
    if (loading || session) return;

    // Initialize the one-tap sign in process
    google.accounts.id.prompt();

    // Attempt to cancel the Google Sign-In process when the component is unmounted
    return google.accounts.id.cancel;
  }, [loading, session]);

  // This component just renders the container that will hold the Google Sign-In button
  return <div id="google-sign-in-container"></div>;
};
