import { ProgressBar } from "react-bootstrap";

export interface NumberBasedProgressBarProps {
  now: number;
  max: number;
  maxFractionDigits?: number;
}

/**
 * A progress par that shows progression towards a total value. The label is centered on the bar, and shows the current
 * value and the total value. The light theme is forced on the element to ensure the text is readable.
 */
export const CountingProgressBar: React.FC<NumberBasedProgressBarProps> = ({ now, max, maxFractionDigits }) => {
  const maximumFractionDigits = maxFractionDigits ?? 5;
  return (
    <ProgressBar
      data-bs-theme="light"
      now={now}
      max={max}
      label={
        <span className="progress-bar-label-centered">
          {now.toLocaleString(undefined, { maximumFractionDigits })} /{" "}
          {max.toLocaleString(undefined, { maximumFractionDigits })}
        </span>
      }
      variant={now === max ? "success" : "warning"}
    />
  );
};

export const PercentageProgressBar: React.FC<NumberBasedProgressBarProps> = ({ now, max, maxFractionDigits }) => {
  const maximumFractionDigits = maxFractionDigits ?? 0;
  const percentage = (now / max) * 100;
  return (
    <ProgressBar
      now={now}
      max={max}
      label={
        <span className="progress-bar-label-centered">
          {percentage.toLocaleString(undefined, { maximumFractionDigits })}%
        </span>
      }
      variant={now === max ? "success" : "warning"}
    />
  );
};
