const defaultMaxRetries = 4;
const defaultBackoffMs = 750;

export type ShouldRetryCallback = (err: Error) => false | string;

export interface ExecuteWithRetriesOptions<T> {
  /** A label to identify the function being executed in log messages. */
  fnLabel: string;

  /** The function to execute and retry if necessary. */
  fn: () => T;

  /**
   * A function that determines whether a given error should be retried. If the function returns a string, that string
   * will be logged as the reason for the retry. If the function returns false, the function will not be retried.
   */
  shouldRetry: ShouldRetryCallback;

  /**
   * The maximum number of times to retry the function before giving up. Defaults to 4 if not provided.
   * NOTE: If we retry 4 times, the total number of attempts will be 5 (the initial attempt plus 4 retries).
   */
  maxRetries?: number;

  /**
   * The duration, in milliseconds, to increase the wait duration before each retry attempt. Defaults to 750ms if not provided.
   */
  backoffMs?: number;
}

/**
 * Execute the provided function, retrying up to the specified number of times if transient errors occur during any
 * Google Sheets API call.
 *
 * This function is an abstract implementation that does not provide its own `sleep` function and should not be used
 * directly. Use the concrete implementation from the common-apps-script or the common-ui package instead.
 */
export function executeWithRetries<T>({
  fnLabel,
  fn,
  shouldRetry,
  maxRetries,
  backoffMs,
}: ExecuteWithRetriesOptions<T>): Promise<T> {
  maxRetries ??= defaultMaxRetries;
  backoffMs ??= defaultBackoffMs;
  const retriesRemaining = maxRetries;
  return executeWithRetriesInternal(fnLabel, fn, shouldRetry, retriesRemaining, maxRetries, backoffMs);
}

export async function executeWithRetriesInternal<T>(
  fnLabel: string,
  fn: () => T,
  shouldRetry: ShouldRetryCallback,
  retriesRemaining: number,
  maxRetries: number,
  backoffMs: number,
): Promise<T> {
  try {
    return await fn();
  } catch (err) {
    return await handleErrorWithRetries(fnLabel, err, retriesRemaining, maxRetries, backoffMs, shouldRetry, () =>
      executeWithRetriesInternal(fnLabel, fn, shouldRetry, retriesRemaining - 1, maxRetries, backoffMs),
    );
  }
}

async function handleErrorWithRetries<T>(
  fnLabel: string,
  err: unknown,
  retriesRemaining: number,
  maxRetries: number,
  backoffMs: number,
  shouldRetry: ShouldRetryCallback,
  retryFunc: () => T,
): Promise<T> {
  // Perform some common checks to determine if the function not be retried
  const prevAttemptNum = maxRetries - retriesRemaining + 1;
  const rethrowPrefix = `${fnLabel}: Attempt #${prevAttemptNum.toFixed()} failed and will not be retried`;
  if (retriesRemaining <= 0) {
    console.error(`${rethrowPrefix} - Maximum number of retries reached: %o`, err);
    throw err;
  } else if (!(err instanceof Error)) {
    console.error(
      `${rethrowPrefix} - Could not determine if retry should be attempted because a non-error object was thrown: %o`,
      err,
    );
    throw err;
  }

  // The caller provides a function to determine when an error should be retried
  const retryReason = shouldRetry(err);
  if (!retryReason) {
    console.error(`${rethrowPrefix} - This is not a retry-able error: %o`, err);
    throw err;
  }

  // We are going to retry the request; log a message before retrying
  console.warn(`${fnLabel}: Retrying execution after '${retryReason}' transient error: %o`, err);

  // Determine the sleep duration using exponential back off with random jitter
  const currentAttemptNum = prevAttemptNum + 1;
  const retryDelay = backoffMs * prevAttemptNum + Math.floor(Math.random() * 500);
  console.debug(
    `${fnLabel}: Sleeping for ${retryDelay.toFixed()}ms before retry attempt #${currentAttemptNum.toFixed()}`,
  );

  // Sleep for the calculated duration
  await new Promise<void>((resolve) => {
    setTimeout(resolve, retryDelay);
  });

  // Retry the request
  console.debug(`${fnLabel}: Initiating retry attempt #${currentAttemptNum.toFixed()}`);
  return await retryFunc();
}
