import React, { useCallback, useMemo } from "react";
import { Container, Form } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";

import { ErrorBoundary } from "components/ErrorBoundary";
import { SalesOrderJob, salesOrderJobsQueryOptions } from "./queries/sales-order-jobs";
import { PageLoader } from "components/PageLoader";
import { DataTable } from "components/DataTable";
import { SosCustomFields } from "utils/sos/custom-fields";
import { FormatDate } from "components/Date";
import { Link } from "react-router";

interface TableRow extends SalesOrderJob {
  customerBillingAddress: string;
  notes: string;
  pressureSystem: boolean;
  electricalDesigner: string;
  mechanicalDesigner: string;
  model: string;
  saleType: string;
  brand: string;
  pressureCertification: string;
  dateOpened: Date | null;
  dateClosed: Date | null;
  orderName: string;
  evolvedOpportunityNumber: number | null;
  serviceEstimateOpportunityNumber: number | null;
}

const columnHelper = createColumnHelper<TableRow>();

const columns = [
  columnHelper.group({
    header: "SOS Job Data",
    columns: [
      columnHelper.accessor("number", { header: "Order #" }),
      columnHelper.accessor("brand", { header: "Brand" }),
      columnHelper.accessor("evolvedOpportunityNumber", { header: "Evolved #" }),
      columnHelper.accessor("serviceEstimateOpportunityNumber", { header: "Evolved # (Service Estimate)" }),
      columnHelper.accessor("customer.name", { header: "Customer" }),
      columnHelper.accessor("customerBillingAddress", { header: "Customer Location" }),
      columnHelper.accessor("saleType", { header: "Sale Type" }),
      columnHelper.accessor("model", { header: "Model" }),
      columnHelper.accessor("pressureSystem", {
        header: "Pressure System",
        cell: ({ getValue }) => (getValue() ? "✅" : "⬜"),
      }),
      columnHelper.accessor("pressureCertification", { header: "Pressure Certification" }),
      columnHelper.accessor("electricalDesigner", { header: "Electrical Designer" }),
      columnHelper.accessor("mechanicalDesigner", { header: "Mechanical Designer" }),
      columnHelper.accessor("orderName", { header: "Order Name" }),
      columnHelper.accessor("notes", { header: "Notes" }),
      columnHelper.accessor<"dateOpened", Date | null>("dateOpened", {
        header: "Date Opened",
        cell: ({ getValue }) => <FormatDate date={getValue()} />,
      }),
      columnHelper.accessor<"dateClosed", Date | null>("dateClosed", {
        header: "Date Closed",
        cell: ({ getValue }) => <FormatDate date={getValue()} />,
      }),
      columnHelper.accessor("closed", {
        header: "Status",
        cell: ({ getValue }) => (getValue() ? "Inactive" : "Active"),
      }),
    ],
  }),
  columnHelper.group({
    header: "Links",
    columns: [
      // columnHelper.display({ header: "Google Drive Folder" }),
      // columnHelper.display({ header: "SOS Job" }),
      // columnHelper.display({ header: "SOS Sales Order" }),
      // columnHelper.display({ header: "QBO Job / Project" }),
      columnHelper.display({
        header: "Tracker",
        cell: ({ row: { original } }) => {
          const jobName = original.number;
          return <Link to={`/sales-order/${jobName}/details`}>{jobName}</Link>;
        },
      }),
    ],
  }),
];

export const SalesOrderLog: React.FC = () => {
  // Define State
  const [showClosed, setShowClosed] = React.useState(false);
  const [showArchived, setShowArchived] = React.useState(false);

  // Define callbacks
  const toggleShowClosed = useCallback(() => {
    setShowClosed(!showClosed);
  }, [showClosed]);
  const toggleShowArchived = useCallback(() => {
    setShowArchived(!showArchived);
  }, [showArchived]);

  // Fetch data
  const jobQuery = useQuery(salesOrderJobsQueryOptions);

  // Filter and map query data into table format
  // NOTE: We aren't using the 'select' option in the query because we want to show the user the number of unfiltered records
  // Note: Use the useMemo hook here so that we aren't recomputing the table data on every render
  const tableData = useMemo((): TableRow[] => {
    return (jobQuery.data ?? [])
      .filter((x) => showClosed || !x.closed)
      .filter((x) => showArchived || !x.archived)
      .map<TableRow>((job) => {
        const orderName = SosCustomFields.getStringValue(job, "orderName") || "";
        return {
          ...job,
          orderName,
          notes: (job.description ?? "").replace(orderName, "").trim(),
          customerBillingAddress: formatCustomerLocation(job.customer),
          brand: SosCustomFields.getListValue(job, "brand") || "",
          evolvedOpportunityNumber: SosCustomFields.getNumberValue(job, "evolvedOpportunityNumber"),
          serviceEstimateOpportunityNumber: SosCustomFields.getNumberValue(job, "serviceEstimateOpportunityNumber"),
          saleType: SosCustomFields.getListValue(job, "saleType") || "",
          model: SosCustomFields.getStringValue(job, "model") || "",
          pressureSystem: SosCustomFields.getBoolValue(job, "pressureSystem") || false,
          pressureCertification: SosCustomFields.getListValue(job, "pressureCertification") || "None",
          electricalDesigner: SosCustomFields.getStringValue(job, "electricalDesigner") || "",
          mechanicalDesigner: SosCustomFields.getStringValue(job, "mechanicalDesigner") || "",
          dateOpened: SosCustomFields.getDateValue(job, "dateOpened")!,
          dateClosed: SosCustomFields.getDateValue(job, "dateClosed"),
        };
      })
      .sort((a, b) => (b.dateOpened?.getTime() ?? 0) - (a.dateOpened?.getTime() ?? 0));
  }, [jobQuery.data, showClosed, showArchived]);

  // Calculate some number for displaying on the filters
  const numClosed = jobQuery.data?.filter((x) => x.closed).length ?? 0;
  const numArchived = jobQuery.data?.filter((x) => x.archived).length ?? 0;

  // Handle loading and error states
  if (jobQuery.isError) return `Error loading sales orders: ${jobQuery.error.message}`;
  if (jobQuery.isPending) return <PageLoader text="Loading sales orders" />;

  return (
    <ErrorBoundary>
      <Container fluid className="py-3 h-100 d-flex flex-column">
        <div className="col-auto">
          <h2>
            Sales Orders{" "}
            <small className="text-body-secondary">
              (Displaying {tableData.length} of {jobQuery.data.length})
            </small>
          </h2>
          <div className="overflow-hidden mb-3">
            <div className="row row-cols-auto g-4 align-items-center">
              <span>Filters:</span>
              <Form.Check
                type="switch"
                label={`Show Closed (${numClosed.toFixed()})`}
                checked={showClosed}
                onChange={toggleShowClosed}
              />
              <Form.Check
                type="switch"
                label={`Show Archived (${numArchived.toFixed()})`}
                checked={showArchived}
                onChange={toggleShowArchived}
              />
            </div>
          </div>
        </div>
        <div className="col sticky-table-container table-responsive">
          <DataTable
            columns={columns}
            data={tableData}
            className="table-bordered"
            initialState={{
              sorting: [{ id: "dateOpened", desc: true }],
            }}
          />
        </div>
      </Container>
    </ErrorBoundary>
  );
};
const formatCustomerLocation = (customer: SalesOrderJob["customer"] | null) => {
  if (!customer) return "";
  const { city, stateProvince, country } = customer.billing;
  return [city, stateProvince, country].filter((x) => x).join(", ");
};
