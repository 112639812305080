import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppRouter } from "app/AppRouter";
import { ErrorBoundary } from "components/ErrorBoundary";
import { GasApiProvider } from "stores/GasApiContext";
import { SessionProvider } from "stores/SessionContext";

// Create a React Query client
const queryClient = new QueryClient({
  // By setting the default staleTime to `infinity`, queries will never be considered stale, and will only re-fetch when
  // the page is reloaded
  defaultOptions: { queries: { staleTime: Infinity } },
});

export function App() {
  return (
    <ErrorBoundary>
      <SessionProvider>
        <GasApiProvider>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </GasApiProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
}
