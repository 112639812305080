export interface LineItem {
  description: string | null;
  item: {
    name: string;
    description: string | null;
  };
}

export const LineItemDescription: React.FC<{ line: LineItem }> = ({ line }) => {
  // Decide if a warning icon should be shown for lines with 700001 items that do not have a custom description
  const showDescriptionWarning =
    line.item.name === "700001" && (!line.description || line.description === line.item.description);
  const descriptionWarning = showDescriptionWarning ? "⚠️" : "";

  // Fall back to just the item description if there is no description on the line item
  if (!line.description) return descriptionWarning + (line.item.description ?? "");

  // Show the line description on its own if it is the same as the item description, or there is no item description
  if (!line.item.description || line.description === line.item.description)
    return descriptionWarning + line.description;

  // Otherwise, show the line description followed by the item description on a new line in parentheses
  // The item description for item 700001 is re-written to be more user-friendly
  const itemDescription = line.item.name === "700001" ? "GENERAL ONE TIME PURCHASE" : line.item.description;
  return (
    <>
      {line.description}
      <br />({itemDescription})
    </>
  );
};
