import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { DataTable } from "components/DataTable";
import { ErrorSpan } from "components/ErrorSpan";
import { hasBom } from "utils/sos/utilities";
import { SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { Item } from "../queries/tracking-data/items";
import { BomComponent } from "../queries/tracking-data/raw-bom";
import { PurchaseTaxCode } from "../queries/tracking-data/vendor";
import { getCostTaxBreakdownColumnDefs } from "./CostTaxBreakdownColumns";
import { withSalesOrderTrackingData } from "./withSalesOrderTrackingData";

const columnHelper = createColumnHelper<BomComponent>();

const columns: ColumnDef<BomComponent>[] = [
  columnHelper.group({
    header: "Item Details",
    columns: [
      columnHelper.accessor("item.name", { header: "Item" }),
      columnHelper.accessor("item.type", { header: "Type" }),
      columnHelper.accessor("item.description", { header: "Description" }),
      columnHelper.accessor("item.preferredVendor.id", {
        header: "Preferred Vendor",
        cell: ({ row }) => <PreferredVendorCellContent item={row.original.item} />,
      }),
      columnHelper.accessor("item.purchaseTaxCode", {
        header: "Tax Rate",
        cell: ({ row }) => <TaxCodeCellContent item={row.original.item} />,
      }),
    ],
  }),
  columnHelper.group({
    header: "Estimated Unit Cost",
    columns: getCostTaxBreakdownColumnDefs<BomComponent>((row) => row.item.preferredPurchaseCost, {
      idPrefix: "item_preferredPurchaseCost",
    }),
  }),
  columnHelper.group({
    header: "Estimated Quantities",
    columns: [
      columnHelper.accessor("totalQuantity", {
        header: "Quantity",
        cell: ({ getValue }) => getValue<number>().toLocaleString(undefined, { maximumFractionDigits: 5 }),
      }),
    ],
  }),
];

const BomByComponent: React.FC<SalesOrderTrackingData> = ({ rawBomComponents }) => (
  <DataTable
    bordered
    hover
    columns={columns}
    data={rawBomComponents}
    initialState={{ sorting: [{ id: "item_name", desc: false }] }}
  />
);

const PreferredVendorCellContent: React.FC<{ item: Item }> = ({ item }) => {
  const vendor = item.preferredVendor;
  const missingVendor = !hasBom(item.type) && !vendor;
  const missingTaxCode = vendor && !vendor.taxCode;
  const error =
    missingVendor ? "no preferred vendor selected"
    : missingTaxCode ? "tax code is missing"
    : null;

  const taxCodeSuffix = vendor?.taxCode ? `(${formatPurchaseTaxCode(vendor.taxCode)})` : "";
  const vendorText = vendor ? `${vendor.name} ${taxCodeSuffix}` : null;

  return (
    <>
      {vendorText} {error && <ErrorSpan>⚠ {error}</ErrorSpan>}
    </>
  );
};

const TaxCodeCellContent: React.FC<{ item: Item }> = ({ item }) => {
  const taxCodeMissing = !item.purchaseTaxCode;
  const taxCodeMismatch = item.preferredVendor?.taxCode && item.purchaseTaxCode?.id !== item.preferredVendor.taxCode.id;
  const error =
    taxCodeMissing ? "tax code is missing"
    : taxCodeMismatch ? "tax code does not match vendor"
    : null;
  return (
    <>
      {item.purchaseTaxCode ? formatPurchaseTaxCode(item.purchaseTaxCode) : ""}{" "}
      {error && <ErrorSpan>⚠ {error}</ErrorSpan>}
    </>
  );
};

function formatPurchaseTaxCode(taxCode: NonNullable<PurchaseTaxCode>) {
  return `${taxCode.name} - ${taxCode.purchaseTaxRate.toLocaleString(undefined, { maximumFractionDigits: 5 })}%`;
}

export default withSalesOrderTrackingData(BomByComponent, "BOM Hierarchy");
