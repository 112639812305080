import { AsyncReturnType } from "type-fest";

import { Job } from "./job-by-name";
import { getCurrencies, getCADCurrencyConverter, getExchangeRates } from "./tracking-data/currency";
import { getRawBomHierarchy, groupBomByComponent } from "./tracking-data/raw-bom";
import { getSalesOrder, getSalesOrderLines } from "./tracking-data/sales-orders";
import { getPurchaseOrders } from "./tracking-data/purchase-orders";
import { getItemReceipts } from "./tracking-data/item-receipts";

export type SalesOrderTrackingData = AsyncReturnType<typeof getSalesOrderTrackingData>;

export async function getSalesOrderTrackingData(job: Job) {
  const salesOrder = await getSalesOrder(job.number);
  const salesOrderLines = getSalesOrderLines(salesOrder, job);
  const exchangeRates = await getExchangeRates();
  const currencies = await getCurrencies(exchangeRates);
  const CADCurrencyConverter = getCADCurrencyConverter(currencies);
  const rawBomHierarchy = await getRawBomHierarchy(salesOrderLines, CADCurrencyConverter);
  const rawBomComponents = groupBomByComponent(rawBomHierarchy);
  const itemReceipts = await getItemReceipts(job.id, CADCurrencyConverter);
  const purchaseOrders = await getPurchaseOrders(job.id, itemReceipts);
  return {
    job,
    salesOrder,
    salesOrderLines,
    exchangeRates,
    currencies,
    CADCurrencyConverter,
    rawBomHierarchy,
    rawBomComponents,
    itemReceipts,
    purchaseOrders,
  };
}
