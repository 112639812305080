import {
  Cell,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  RowData,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode } from "react";
import { Table, TableProps } from "react-bootstrap";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    headerCellProps?: HeaderCellPropsCallback<TData, TValue>;
    cellProps?: DataCellPropsCallback<TData, TValue>;
  }
}

export type HeaderCellPropsCallback<TData extends RowData, TValue> = (
  header: Header<TData, TValue>,
) => React.TdHTMLAttributes<HTMLTableCellElement>;

export type DataCellPropsCallback<TData extends RowData, TValue> = (
  cell: Cell<TData, TValue>,
) => React.TdHTMLAttributes<HTMLTableCellElement>;

export interface DataTableProps<TData> extends BaseTableProps<TData> {
  /** By default table footers are not rendered. Set this to true to opt-in */
  showFooter?: boolean;
}

type BaseTableProps<TData> = Pick<TableOptions<TData>, "columns" | "data" | "state" | "initialState"> &
  TableProps &
  React.RefAttributes<HTMLTableElement>;

export const DataTable = <T,>({
  columns,
  data,
  state,
  initialState,
  showFooter,
  ...tableProps
}: DataTableProps<T>): ReactNode => {
  const table = useReactTable({
    data,
    columns,
    state,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
  });

  return (
    <Table size="sm" {...tableProps}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} data-header-group-id={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const canSort = header.column.getCanSort();
              const isSorted = header.column.getIsSorted();
              const sortIcon =
                !canSort ? null
                : isSorted === "asc" ? "🔼"
                : isSorted === "desc" ? "🔽"
                : null;
              const sortIndex = header.column.getSortIndex();
              const numSortedColumns = table.getState().sorting.length;
              const sortIndexIndicator =
                isSorted && numSortedColumns > 1 ? <sup>{sortIndex + 1}</sup> : <sup className="invisible">0</sup>;

              const sortSuffix =
                canSort ?
                  <>
                    &nbsp;
                    <pre className="d-inline">
                      {sortIcon}
                      {sortIndexIndicator}
                    </pre>
                  </>
                : null;

              const { className, ...metaProps } = header.column.columnDef.meta?.headerCellProps?.(header) ?? {};

              return (
                <th
                  key={header.id}
                  data-header-id={header.id}
                  colSpan={header.colSpan}
                  className={`text-center align-middle ${className ?? ""}` + (canSort ? " cursor-pointer" : "")}
                  onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
                  {...metaProps}
                >
                  {!header.isPlaceholder && (
                    <>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {sortSuffix}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="table-group-divider">
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const cellProps = cell.column.columnDef.meta?.cellProps?.(cell) ?? {};
              return (
                <td key={cell.id} {...cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      {showFooter && (
        <tfoot className="table-group-divider">
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {!header.isPlaceholder && flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </Table>
  );
};
