import { useState } from "react";
import { Button, Collapse, Nav } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";

import { Icon } from "components/Icon";
import LinkContainer from "components/LinkContainer";
import { Job } from "./queries/job-by-name";

export const SalesOrderTrackerNav: React.FC<{ job: Job }> = ({ job }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const toggleDetails = () => {
    setDetailsOpen((open) => !open);
  };

  const evolvedOpportunityNumbers = [
    job.customFieldValues.find((x) => x.customFieldDefinitionId === 28)?.value,
    job.customFieldValues.find((x) => x.customFieldDefinitionId === 31)?.value,
  ]
    .filter((x): x is string => !!x)
    .map((x) => `GNM#${x}`)
    .join(", ");

  const orderDetails: [string, string | undefined | null][] = [
    ["Evolved #", evolvedOpportunityNumbers],
    ["Customer", job.customer.name],
    ["Model", job.customFieldValues.find((x) => x.customFieldDefinitionId === 18)?.value],
    ["Date", job.customFieldValues.find((x) => x.customFieldDefinitionId === 22)?.value],
  ];

  return (
    <Nav activeKey={window.location.href} className="flex-column">
      <Nav.Item className="text-wrap px-3">
        <Button variant="link" size="sm" onClick={toggleDetails} className="px-0 text-reset text-decoration-none">
          <h1 className="h3 d-inline m-0">{job.number}</h1>
          <Icon icon={detailsOpen ? "caret-down-square" : "caret-right-square"} className="ms-2 mb-1" />
        </Button>
        <Collapse in={detailsOpen}>
          <dl className="m-0">
            {orderDetails
              .filter(([, value]) => value)
              .map(([label, value], index, array) => (
                <Fragment key={label}>
                  <dt>{label}</dt>
                  <dd className={index === array.length - 1 ? "m-0" : ""}>{value}</dd>
                </Fragment>
              ))}
          </dl>
        </Collapse>
      </Nav.Item>
      <hr />
      <LinkContainer to={`/sales-order/${job.number}/details`}>
        <Nav.Link>
          <Icon icon="file-text" className="me-2" />
          Order Details
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/financial-summary`}>
        <Nav.Link>
          <Icon icon="cash-coin" className="me-2" />
          Financial Summary
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/erns`}>
        <Nav.Link>
          <Icon icon="gear-wide-connected" className="me-2" />
          ERNs
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/bom-hierarchy`}>
        <Nav.Link>
          <Icon icon="list-nested" className="me-2" />
          BOM Hierarchy
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/bom-by-component`}>
        <Nav.Link>
          <Icon icon="list" className="me-2" />
          BOM By Component
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/existing-inventory`}>
        <Nav.Link>
          <Icon icon="boxes" className="me-2" />
          Existing Inventory
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/purchase-orders`}>
        <Nav.Link>
          <Icon icon="cart" className="me-2" />
          Purchase Orders
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/purchasing-progress`}>
        <Nav.Link>
          <Icon icon="file-text" className="me-2" />
          Purchasing Progress
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/landed-costs`}>
        <Nav.Link>
          <Icon icon="receipt" className="me-2" />
          Landed Costs
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/work-orders`}>
        <Nav.Link>
          <Icon icon="wrench-adjustable" className="me-2" />
          Work Orders
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/work-order-creation`}>
        <Nav.Link>
          <Icon icon="wrench-adjustable-circle" className="me-2" />
          Work Order Creation
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/builds`}>
        <Nav.Link>
          <Icon icon="hammer" className="me-2" />
          Builds
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/fabrication-progress`}>
        <Nav.Link>
          <Icon icon="tools" className="me-2" />
          Fabrication Progress
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/sales-order/${job.number}/shipments`}>
        <Nav.Link>
          <Icon icon="truck" className="me-2" />
          Shipments
        </Nav.Link>
      </LinkContainer>
    </Nav>
  );
};
