import { Container } from "react-bootstrap";

export interface PageLoaderProps {
  text: string;
}
export const PageLoader: React.FC<PageLoaderProps> = ({ text }) => (
  <Container className="flex-grow-1 d-flex flex-column justify-content-center text-center mt-5">
    <div>
      <div className="spinner-border me-3" role="status" />
      <h1 className="d-inline">{text}...</h1>
    </div>
  </Container>
);
