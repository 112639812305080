import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { DataCellPropsCallback } from "components/DataTable";
import { errorTextClass } from "components/ErrorSpan";
import { Dinero, formatDineroCad } from "utils/dinero-util";
import { CostTaxBreakdown } from "../queries/tracking-data/cost-tax-breakdown";

export function getCostTaxBreakdownColumnDefs<TData>(
  costAccessor: (row: TData) => CostTaxBreakdown,
  {
    idPrefix,
    cellProps,
    enableSorting,
  }: { idPrefix: string; cellProps?: DataCellPropsCallback<TData, Dinero>; enableSorting?: boolean },
): ColumnDef<TData, Dinero>[] {
  const columnHelper = createColumnHelper<TData>();

  return [
    // Subtotal Column - If there are errors, they will be rendered in this column and it will span all 3 columns
    columnHelper.accessor((row: TData) => costAccessor(row).subtotal, {
      id: idPrefix + "_subtotal",
      header: "Subtotal",
      cell: ({ row, getValue }) => {
        const cost = costAccessor(row.original);
        return cost.errors.length ?
            <CostTaxBreakdownErrors errors={cost.errors} />
          : <>{formatDineroCad(getValue())}</>;
      },
      enableSorting,
      meta: {
        // Set the colspan to 3 if there is an error so that the error message spans all three cells
        cellProps: (cell) => ({
          colSpan: costAccessor(cell.row.original).errors.length ? 3 : 1,
          ...cellProps?.(cell),
        }),
      },
    }),

    // Tax Column - If there are errors, this column will render no content nothing and be hidden
    columnHelper.accessor((row: TData) => costAccessor(row).tax, {
      id: idPrefix + "_tax",
      header: "Tax",
      cell: ({ row, getValue }) => {
        const cost = costAccessor(row.original);
        return cost.errors.length ? null : <>{formatDineroCad(getValue())}</>;
      },
      enableSorting,
      meta: {
        // Hide the column if there are errors
        cellProps: (cell) => ({
          hidden: costAccessor(cell.row.original).errors.length ? true : undefined,
          ...cellProps?.(cell),
        }),
      },
    }),

    // Total Column - If there are errors, this column will render no content nothing and be hidden
    columnHelper.accessor((row: TData) => costAccessor(row).total, {
      id: idPrefix + "_total",
      header: "Total",
      cell: ({ row, getValue }) => {
        const cost = costAccessor(row.original);
        return cost.errors.length ? null : <>{formatDineroCad(getValue())}</>;
      },
      enableSorting,
      meta: {
        // Hide the column if there are errors
        cellProps: (cell) => ({
          hidden: costAccessor(cell.row.original).errors.length ? true : undefined,
          ...cellProps?.(cell),
        }),
      },
    }),
  ];
}

export const CostTaxBreakdownErrors: React.FC<{ errors: CostTaxBreakdown["errors"] }> = ({ errors }) => {
  if (errors.length > 1) {
    // Display errors as a bulleted list
    return (
      <ul>
        {errors.map((error, i) => (
          <li key={i} className={errorTextClass}>
            ⚠ {error}
          </li>
        ))}
      </ul>
    );
  } else if (errors.length) {
    // Display a single error
    return <span className={errorTextClass}>⚠ {errors[0]}</span>;
  } else {
    throw new Error("Cannot render TaxBreakdownErrors component without errors");
  }
};
