import { useQuery, UseQueryResult } from "@tanstack/react-query";
import React from "react";

import { PageLoader } from "components/PageLoader";
import { getSalesOrderTrackingData, SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { withJob, WithJobProps } from "./withJob";

export type SalesOrderTrackerRouteParams = "jobNumber";

export interface WithSalesOrderTrackingDataQueryProps extends WithJobProps {
  trackingDataQuery: UseQueryResult<SalesOrderTrackingData>;
}

/**
 * Higher-Order Component to fetch Sales Order tracking data. The tracking data is passed to the wrapped component, which
 * must choose how to display a loading state while the data is loading.
 *
 * This component requires the job to be loaded and passed as a prop (i.e. with the `withJob` HoC) before it can fetch
 * the tracking data. The job is also passed through to the wrapped component.
 */
export const withSalesOrderTrackingDataQuery = (WrappedComponent: React.FC<WithSalesOrderTrackingDataQueryProps>) => {
  return withJob((props: WithJobProps) => {
    const { job } = props;
    const trackingDataQuery = useQuery<SalesOrderTrackingData>({
      queryKey: ["SalesOrderTracking", job.number],
      queryFn: async () => await getSalesOrderTrackingData(job),
      retry: false,
    });

    return <WrappedComponent trackingDataQuery={trackingDataQuery} {...props} />;
  });
};

/**
 * Higher-Order Component to fetch Sales Order tracking data. A loading page is rendered while the tracking data is
 * loading. The wrapped component is only rendered when the tracking data is fully loaded, which is passed as a prop to
 * the wrapped component.
 *
 * This component requires the job to be loaded and passed as a prop (i.e. with the `withJob` HoC) before it can fetch
 * the tracking data. The job is also passed through to the wrapped component.
 */
export const withSalesOrderTrackingData = (
  WrappedComponent: React.FC<SalesOrderTrackingData>,
  loadingLabel: string,
) => {
  return withSalesOrderTrackingDataQuery((props: WithSalesOrderTrackingDataQueryProps) => {
    const { trackingDataQuery } = props;

    // Wait for query to finish loading before rendering the wrapped component
    if (trackingDataQuery.isError) throw trackingDataQuery.error;
    if (trackingDataQuery.isPending) return <PageLoader text={`Loading ${loadingLabel}`} />;

    return <WrappedComponent {...trackingDataQuery.data} {...props} />;
  });
};
