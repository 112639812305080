import { ScaledAmount as AbstractScaledAmount } from "@dinero.js/core";
import * as currencies from "@dinero.js/currencies";
import { Dinero as AbstractDinero, convert, dinero, multiply, Transformer, toDecimal } from "dinero.js";

export type SupportedCurrencyCode = keyof typeof currencies;

/**
 * A dinero object, using `number` as the amount type. Dinero supports other amount types, but we only use `number` in
 * this project.
 * See: https://v2.dinerojs.com/docs/guides/using-different-amount-types
 */
export type Dinero = AbstractDinero<number>;

type ScaledAmount = AbstractScaledAmount<number>;

/** A Dinero object with a value of $0.00 in CAD */
export const dineroCADZero = dineroCADFromFloatingPoint(0);

/**
 * Create a dinero object from the provided floating point number in a potentially foreign currency, converted to CAD.
 *
 * See: https://v2.dinerojs.com/docs/api/conversions/convert
 *
 * @param floatingPointAmount The amount in the foreign currency.
 * @param currencyCode The currency code of the foreign currency.
 * @param exchangeRate The exchange rate as the multiplied used when converting from CAD to the foreign currency (i.e. USD might be about 0.7). This
 * matches the format used for exchange rates in SOS (i.e. on Purchase Orders).
 */
export function dineroCadFromFloatingPointForeignAmount(
  floatingPointAmount: number,
  currencyCode: SupportedCurrencyCode,
  exchangeRate: number,
): Dinero {
  // If the currency is CAD, convert directly to a CAD dinero object
  if (currencyCode === "CAD") {
    if (exchangeRate !== 1)
      throw new Error(`Expected exchange rate to be 1 for CAD currency, but got ${exchangeRate.toString()}`);
    return dineroCADFromFloatingPoint(floatingPointAmount);
  }

  // Create a dinero object in the specified currency
  const costAmountLocal = dineroForCurrencyFromFloatingPoint(floatingPointAmount, currencyCode);

  // Invert the exchange rate and scale it to avoid floating point errors
  const inverseScaledExchangeRate = invertAndRoundFloatingPointExchangeRate(exchangeRate);

  // Convert the amount to CAD
  return convert(costAmountLocal, currencies.CAD, { CAD: inverseScaledExchangeRate });
}

/**
 * Create a CAD dinero object from a floating point number. This uses the default currency scale of 2, rounding off any
 * additional precision.
 *
 * To create a dinero object with additional precision, use the dinero constructor directly. For examples of when you
 * might want to use additional precision, see the Dinero documentation:
 * https://v2.dinerojs.com/docs/core-concepts/scale#when-to-specify-a-scale-manually
 */
export function dineroCADFromFloatingPoint(floatingPointAmount: number): Dinero {
  return dineroForCurrencyFromFloatingPoint(floatingPointAmount, "CAD");
}

/**
 * Create a dinero object for the specified currency from a floating point number. This uses the default currency scale
 * for the specified currency, rounding off any additional precision.
 *
 * To create a dinero object with additional precision, use the dinero constructor directly. For examples of when you
 * might want to use additional precision, see the Dinero documentation:
 * https://v2.dinerojs.com/docs/core-concepts/scale#when-to-specify-a-scale-manually
 */
export function dineroForCurrencyFromFloatingPoint(
  floatingPointAmount: number,
  currency: SupportedCurrencyCode,
): Dinero {
  const amount = Math.round(floatingPointAmount * Math.pow(10, currencies[currency].exponent));
  return dinero({ amount, currency: currencies[currency] });
}

export function validateCurrencyCode(code: string): SupportedCurrencyCode {
  if (!(code in currencies)) {
    throw new Error(`Invalid currency code: ${code}`);
  }
  return code as SupportedCurrencyCode;
}

/**
 * Multiply the provided Dinero instance by the provided value, using the specified scale (i.e. number of decimal places).
 *
 * This simplifies multiplication by a floating point number with an arbitrary number of decimal places.
 *
 * For example, if the multiplier provided is 3.14159 and scale is 2, the multiplier actually used will be 3.14.
 *
 * NOTE: See the Dinero documentation for details on why a scale must be specified when multiplying by a floating point number:
 * https://v2.dinerojs.com/docs/core-concepts/scale#calculate-objects-of-different-scales
 */
export function multiplyScaled(multiplicand: Dinero, multiplier: Required<ScaledAmount>): Dinero {
  const scaledAmount = roundFloatToScale(multiplier.amount, multiplier.scale);
  return multiply(multiplicand, scaledAmount);
}

/**
 * Create a function that can convert from any currency to CAD.
 *
 * See: https://v2.dinerojs.com/docs/api/conversions/convert
 *
 * @param rates A map of currency codes to exchange rates. The rates provided should be a map of the foreign currency
 * code to the multiplier used when converting from CAD to the foreign currency (i.e. USD might be about 0.7). This
 * matches the format used for exchange rates in SOS (i.e. on Purchase Orders).
 */
export function createCADCurrencyConverter(rates: Record<string, number>): (dineroObject: Dinero) => Dinero {
  // Create a new map of rates, inverting them, and scaling the rates to avoid floating point errors
  const invertedAndScaledRates = Object.fromEntries(
    Object.entries(rates).map(([code, rate]) => [code, invertAndRoundFloatingPointExchangeRate(rate)]),
  );

  // Return a function that can convert from any supported currency to CAD
  return (dineroObject: Dinero) => {
    const sourceCurrencyCode = dineroObject.toJSON().currency.code;
    if (sourceCurrencyCode === "CAD") return dineroObject;
    const rate = invertedAndScaledRates[sourceCurrencyCode]!;
    return convert(dineroObject, currencies.CAD, { CAD: rate });
  };
}

/**
 * Invert the provided exchange rate and scale it to avoid floating point errors when converting between
 * currencies. The exchange rate is scaled with a precision of 8 decimal places, since that is what is used in SOS and is
 * more than enough precision for our purposes.
 */
function invertAndRoundFloatingPointExchangeRate(exchangeRate: number): Required<ScaledAmount> {
  return roundFloatToScale(1 / exchangeRate, 8);
}

/**
 * Convert a floating point number to a scaled amount that can be used safely by dinero.js, which only deals in integers.
 * Any precision beyond the scale will be rounded off and lost.
 */
function roundFloatToScale(floatAmount: number, scale: number): Required<ScaledAmount> {
  return { amount: Math.round(floatAmount * Math.pow(10, scale)), scale };
}

/** Format a dinero object as a string with the "en-CA" locale. */
export function formatDineroCad(dineroObject: Dinero, options: Intl.NumberFormatOptions = {}) {
  return formatDinero(dineroObject, "en-CA", options);
}

/**
 * Format a dinero object as a string with the specified locale. If no locale is specified, the user's current locale
 * will be used by default.
 *
 * See: https://v2.dinerojs.com/docs/guides/formatting-in-a-multilingual-site
 */
export function formatDinero(
  dineroObject: Dinero,
  locale: Intl.LocalesArgument = undefined,
  options: Intl.NumberFormatOptions = {},
) {
  const transformer: Transformer<number, string, unknown> = ({ value, currency }) => {
    return Number(value).toLocaleString(locale, {
      ...options,
      style: "currency",
      currency: currency.code,
    });
  };

  return toDecimal(dineroObject, transformer);
}
