import * as Sentry from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";

import { App } from "./app/App";

// Initialize Sentry when running in production
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://82acc39192f38bf46f85935148d01f02@o436832.ingest.us.sentry.io/4508359628554240",
    integrations: [
      // See: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/v7/
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// Render App
const container = document.getElementById("app");
if (!container) throw new Error('Container element with id "app" not found');
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
