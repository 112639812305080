import { ConditionalKeys, ValueOf } from "type-fest";

import { StarNumber, ItemType, EntityPath, LinkedTransactionType, TypeForEntityPath } from "types/sos-types";

export type StarEmoji<UnstarredEmoji = "❓"> = "🔴" | "🟡" | "🟢" | UnstarredEmoji;

const starColorMapping: Record<StarNumber, StarEmoji<null>> = {
  0: null,
  1: "🔴",
  2: "🟡",
  3: "🟢",
};

export function getSosStarEmoji(starred: StarNumber): StarEmoji;
export function getSosStarEmoji<TUnstarredEmoji extends string>(
  starred: StarNumber,
  unstarredEmoji: TUnstarredEmoji,
): StarEmoji<TUnstarredEmoji>;
export function getSosStarEmoji<TUnstarredEmoji extends string | undefined = undefined>(
  starred: StarNumber,
  unstarredEmoji?: TUnstarredEmoji,
): StarEmoji<TUnstarredEmoji extends undefined ? "❓" : TUnstarredEmoji> {
  return (starColorMapping[starred] ?? unstarredEmoji ?? "❓") as StarEmoji<
    TUnstarredEmoji extends undefined ? "❓" : TUnstarredEmoji
  >;
}

/**
 * Check if this item should be considered a material cost. Material costs are physical items (as opposed to non-material
 * costs such as labour, services, etc).
 *
 * NOTE: The type "Non-inventory Item" is included here, because it is used for things like Kanban items, that are
 * physical items, but we do not track as individual parts.
 */
export function isMaterialCost(itemType: ItemType) {
  return itemType === "Inventory Item" || itemType === "Assembly" || itemType === "Non-inventory Item";
}

/**
 * Check if this type of item has a inventory stock count tracked in SOS. When this returns true, the item must be
 * considered when checking for available stock of build dependencies for work orders.
 */
export function hasInventoryTracking(itemType: ItemType) {
  return itemType === "Inventory Item" || itemType === "Assembly";
}

/**
 * Check if this item type is stored in a bin location. Item types that return true from this function display a bin
 * location dropdown on item receipts.
 *
 * NOTE: This is equivalent to hasInventoryTracking(). Items that have inventory tracking also have a bin location.
 */
export const hasBinLocation = hasInventoryTracking;

export function hasBom(itemType: ItemType) {
  return itemType === "Assembly" || itemType === "Item Group";
}

export type EntityWithUrl = keyof typeof entityApiPathToUrlPath;

// TODO: Add paths for remaining entity types
const entityApiPathToUrlPath = {
  customer: "Customer",
  vendor: "Vendor",
  item: "Item",
  salesorder: "SalesOrder",
  purchaseorder: "PurchaseOrder",
  itemreceipt: "ItemReceipt",
  build: "BuildAssembly",
  workorder: "WorkOrder",
  shipment: "Shipment",
  adjustment: "InventoryAdjustment",
  transfer: "InventoryTransfer",
  job: "Job",
} as const satisfies Partial<Record<EntityPath, string>>;

export function getSosEntityUrl(entityType: EntityWithUrl, id: number) {
  return `https://live.sosinventory.com/${entityApiPathToUrlPath[entityType]}/IndexView/${id.toFixed()}`;
}

export type LinkedTransactionTypeToEntityPathMapping = typeof linkedTransactionTypeToEntityPathMapping;

export type LinkableEntityPath = ValueOf<LinkedTransactionTypeToEntityPathMapping>;

const linkedTransactionTypeToEntityPathMapping = {
  IR: "itemreceipt",
  PO: "purchaseorder",
  SO: "salesorder",
  WO: "workorder",
  SR: "salesreceipt",
  Invoice: "invoice",
} as const satisfies Record<LinkedTransactionType, EntityPath>;

export function linkedTransactionTypeToEntityPath(linkedTransactionType: LinkedTransactionType): LinkableEntityPath {
  return linkedTransactionTypeToEntityPathMapping[linkedTransactionType];
}

/**
 * This maps a linkable entity path to the associated field referenced by the `refNumber` in linked transactions.
 *
 * This isn't strictly necessary for now, because all the entities use the `number` field, but it's here for future
 * in case we come across an entity that uses a different field.
 */
export const refNumberFieldForEntityPath = {
  itemreceipt: "number",
  purchaseorder: "number",
  salesorder: "number",
  workorder: "number",
  salesreceipt: "number",
  invoice: "number",
} as const satisfies {
  [TEntityPath in LinkableEntityPath]: ConditionalKeys<TypeForEntityPath<TEntityPath>, string>;
};
