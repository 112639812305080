import { QueryData } from "@supabase/supabase-js";
import { queryOptions } from "@tanstack/react-query";

import { supabase } from "lib/supabase";

export type Job = QueryData<ReturnType<typeof getJobByNameQuery>>;

function getJobByNameQuery(name: string) {
  return supabase
    .from("Job")
    .select("*, customer:Customer!inner(id,name), customFieldValues:JobCustomFieldValue(*)")
    .eq("number", name)
    .eq("deleted", false)
    .limit(1)
    .single()
    .throwOnError();
}

export function getJobByNameQueryOptions(name: string) {
  return queryOptions({
    queryKey: ["Job", "by-name", name],
    queryFn: async () => (await getJobByNameQuery(name)).data,
  });
}
