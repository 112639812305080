import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { ValueOf } from "type-fest";

import { supabase } from "lib/supabase";
import { Database, Tables } from "types/supabase-types";
import { hasBom } from "utils/sos/utilities";
import { CADCurrencyConverter } from "./currency";
import { Item, itemSelect, processItem } from "./items";
import { SalesOrderLineItem } from "./sales-orders";

export interface BomNode {
  lineNumber: number;
  item: Item;
  quantity: number;
  totalQuantity: number;
  notes: string | null;
  isAssembly: boolean;
  recursiveDepth: number;
  children: BomNode[];
  asciiTreePrefix: string;
}

export interface BomComponent {
  item: Item;
  totalQuantity: number;
  isAssembly: boolean;
}

type SupabaseBOMRecord = Database["public"]["Functions"]["getExpandedBOMs"]["Returns"][0];

export async function getRawBomHierarchy(
  salesOrderLines: SalesOrderLineItem[],
  cadCurrencyConverter: CADCurrencyConverter,
): Promise<BomNode[]> {
  if (!salesOrderLines.length) throw new Error("No line items were provided to expand BOM");

  console.info(
    `Expanding BOM for ${salesOrderLines.length.toFixed()} line items: ${salesOrderLines
      .map((x) => x.item.name)
      .join(",")}`,
  );

  // Generate the BOM hierarchy
  return await fetchBomHierarchy(
    salesOrderLines
      .filter((x) => x.item.type === "Assembly")
      .map((line) => ({ item: line.item, quantity: line.quantity })),
    cadCurrencyConverter,
  );
}

async function fetchBomHierarchy(
  parents: { item: Tables<"Item">; quantity: number }[],
  cadCurrencyConverter: CADCurrencyConverter,
) {
  // Do nothing if no parents were specified
  if (!parents.length) return [];

  // Fetch BOM records for all assemblies
  const assemblyIds = uniq(parents.filter((x) => x.item.type === "Assembly").map((x) => x.item.id));
  const bomRecords = (await supabase.rpc("getExpandedBOMs", { assemblyIds }).throwOnError()).data;
  console.info(
    `Fetched ${bomRecords.length.toFixed()} expanded BOM records for ${assemblyIds.length.toFixed()} assemblies from ${parents.length.toFixed()} parent line items`,
  );

  // Group the BOM records by path
  const bomRecordsByPath = groupBy(bomRecords, (x) => x.path.join(","));

  // Fetch item records for all items in the BOM
  const componentIds = uniq([...assemblyIds, ...bomRecords.map((x) => x.componentId)]);
  console.info(`Fetching ${componentIds.length.toFixed()} BOM component item records`);
  const components = (await supabase.from("Item").select(itemSelect).in("id", componentIds).throwOnError()).data;
  const componentsById = new Map(components.map((x) => [x.id, processItem(x, cadCurrencyConverter)]));

  // Create dummy BOM records for the provided parents, then recursively generate the BOM hierarchy
  return parents
    .map<SupabaseBOMRecord>((parent, index) => ({
      assemblyId: NaN,
      lineNumber: index + 1,
      componentId: parent.item.id,
      quantity: parent.quantity,
      notes: "",
      path: [],
      depth: -1,
    }))
    .map((x) => supabaseBomRecordToHierarchicalBomNode(x, [], 1, componentsById, bomRecordsByPath));
}

function supabaseBomRecordToHierarchicalBomNode(
  currentRecord: SupabaseBOMRecord,
  asciiTreeComponents: ValueOf<typeof ASCII_TREE_STRINGS>[],
  parentTotalQuantity: number,
  componentsById: Map<number, Item>,
  bomRecordsByPath: Record<string, SupabaseBOMRecord[]>,
): BomNode {
  // Get the item for the current parent record
  const item = componentsById.get(currentRecord.componentId);
  if (!item) throw new Error(`Component with ID ${currentRecord.componentId.toFixed()} not found while expanding BOM`);

  // Get the BOM records for this item (will be an empty array for non-assemblies)
  const pathString = [...currentRecord.path, currentRecord.componentId].join(",");
  const bomRecords = bomRecordsByPath[pathString];

  // Recursively fetch the BOM for each child
  const totalQuantity = currentRecord.quantity * parentTotalQuantity;
  const children = (bomRecords ?? []).map((x, index, arr) => {
    // Determine the components of the ASCII tree for this child
    // Swap out the parent's last leaf component for the appropriate tree component, then add a leaf segment for the child
    const parentComponents = [...asciiTreeComponents];
    if (parentComponents.length) {
      parentComponents.push(
        parentComponents.pop() === ASCII_TREE_STRINGS.LAST_CHILD ?
          ASCII_TREE_STRINGS.EMPTY
        : ASCII_TREE_STRINGS.DIRECTORY,
      );
    }
    const isLastChild = index === arr.length - 1;
    const childComponent = isLastChild ? ASCII_TREE_STRINGS.LAST_CHILD : ASCII_TREE_STRINGS.CHILD;
    const childAsciiTreeComponents = [...parentComponents, childComponent];

    return supabaseBomRecordToHierarchicalBomNode(
      x,
      childAsciiTreeComponents,
      totalQuantity,
      componentsById,
      bomRecordsByPath,
    );
  });

  // Create a BOM node for the current item
  return {
    lineNumber: currentRecord.lineNumber,
    item,
    quantity: currentRecord.quantity,
    totalQuantity,
    notes: currentRecord.notes,
    isAssembly: hasBom(item.type),
    recursiveDepth: currentRecord.depth + 1,
    children,
    asciiTreePrefix: asciiTreeComponents.join(""),
  };
}

export function groupBomByComponent(bomHierarchy: BomNode[]): BomComponent[] {
  const flattenedNodes = bomHierarchy.flatMap((node) => {
    const { item, isAssembly, totalQuantity, children } = node;
    const parentRecord: BomComponent = { item, isAssembly, totalQuantity };
    const childRecords = groupBomByComponent(children);
    return [parentRecord, ...childRecords];
  });

  // Group the flattened nodes by item ID, sum the total quantities, and sort by item name
  return Object.values(groupBy(flattenedNodes, (x) => x.item.id))
    .map((group) => {
      const totalQuantity = group.reduce((acc, x) => acc + x.totalQuantity, 0);
      return { ...group[0]!, totalQuantity };
    })
    .sort((a, b) => a.item.name.localeCompare(b.item.name));
}

const ASCII_TREE_STRINGS = {
  CHILD: "  ├── ",
  LAST_CHILD: "  └── ",
  DIRECTORY: "  │   ",
  EMPTY: "      ",
} as const;
