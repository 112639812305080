import { supabaseFetchAllPages } from "lib/supabase";
import { Currency } from "types/sos-types";
import { createCADCurrencyConverter, Dinero } from "utils/dinero-util";

interface ExchangeRateResponse {
  groupDetails: object;
  terms: object;
  seriesDetails: object;
  observations: FxDateObservation[];
}

type FxDateObservation = FxDateObservationPartial & { d: string };

type FxDateObservationPartial = Record<string, { v: number }>;

export async function getExchangeRates(): Promise<Map<string, number>> {
  // Fetch the latest exchange rate using the Bank of Canada's API
  const response = await fetch(
    "https://www.bankofcanada.ca/valet/observations/group/FX_RATES_DAILY/json?recent=1&order_dir=desc",
  );

  // Validate response code
  if (!response.ok) {
    const responseCode = response.status.toFixed();
    const responseText = await response.text();
    throw new Error(`Request for exchange rates failed with status code ${responseCode}: ${responseText}`);
  }

  // Get a list of exchange rate keys in the returned data
  const exchangeRateResponse = (await response.json()) as ExchangeRateResponse;
  const fxObservation = exchangeRateResponse.observations[0];
  if (!fxObservation) throw new Error("No exchange rate data found in response");
  const rateKeys = Object.keys(fxObservation).filter(
    (x) => typeof x === "string" && x !== "d" && x.startsWith("FX") && x.endsWith("CAD"),
  );

  // Get a list of exchange rates
  // NOTE: Use the inverse of the exchange rate provided by the BoC API in order to match how exchange rates are represented in SOS (i.e. on purchase orders)
  const rates = rateKeys.map((key) => {
    const countryCode = key.substring(2, key.length - 3);
    const exchangeRate = 1 / fxObservation[key]!.v;
    return { countryCode, exchangeRate };
  });

  // Return a mapping of the country code to the associated exchange rate
  return new Map(rates.map((x) => [x.countryCode, x.exchangeRate]));
}

export async function getCurrencies(exchangeRates: Map<string, number>): Promise<Map<number, Currency>> {
  // Fetch all currencies from supabase
  const supabaseCurrencies = await supabaseFetchAllPages("Currency");

  // Merge the exchange rates with the currencies to create currency objects that match SOS
  const currencies = supabaseCurrencies.map<Currency>((currency) => {
    const exchangeRate = currency.code === "CAD" ? 1 : exchangeRates.get(currency.code)!;
    return { ...currency, exchangeRate };
  });

  return new Map(currencies.map((x) => [x.id, x]));
}

export type CADCurrencyConverter = (dineroObject: Dinero) => Dinero;

export function getCADCurrencyConverter(currencies: Map<number, Currency>): CADCurrencyConverter {
  const rates = Object.fromEntries(
    Array.from(currencies.values()).map((currency) => [currency.code, currency.exchangeRate]),
  );
  return createCADCurrencyConverter(rates);
}
