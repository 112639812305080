import { Route } from "react-router";

import SalesOrderTrackerLayout from "./SalesOrderTrackerPage";
import BomHierarchy from "./components/BomHierarchy";
import SalesOrderDetails from "./components/SalesOrderDetails";
import BomByComponent from "./components/BomByComponent";
import LandedCosts from "./components/LandedCosts";
import PurchaseOrders from "./components/PurchaseOrders";

export const salesOrderTrackerRoutes = (
  <Route path="sales-order/:jobNumber" element={<SalesOrderTrackerLayout />}>
    <Route path="details" element={<SalesOrderDetails />} />
    <Route path="financial-summary" element={<div>TODO: Financial Summary</div>} />
    <Route path="erns" element={<div>TODO: ERNS</div>} />
    <Route path="bom-hierarchy" element={<BomHierarchy />} />
    <Route path="bom-by-component" element={<BomByComponent />} />
    <Route path="existing-inventory" element={<div>TODO: Existing Inventory</div>} />
    <Route path="purchase-orders" element={<PurchaseOrders />} />
    <Route path="purchasing-progress" element={<div>TODO: Purchasing Progress</div>} />
    <Route path="landed-costs" element={<LandedCosts />} />
    <Route path="work-orders" element={<div>TODO: Work Orders</div>} />
    <Route path="work-order-creation" element={<div>TODO: Work Order Creation</div>} />
    <Route path="builds" element={<div>TODO: Builds</div>} />
    <Route path="fabrication-progress" element={<div>TODO: Fabrication Progress</div>} />
    <Route path="shipments" element={<div>TODO: Shipments</div>} />
  </Route>
);
