import { GasApiRequestPoller } from "./gas-api-request-poller";
import googleAuthorizationTokenFetcher from "./google-authorization-token-fetcher";

export type UrlQueryParams = Record<string, string | number | boolean | undefined>;

/**
 * Represents the parameters sent/received for a request to a Google Apps script web app via the query parameters in the URL.
 */
export interface GasRequest extends UrlQueryParams {
  method: string;
}

export type GasResponse<T> = GasSuccessResponse<T> | GasErrorResponse;

export interface GasSuccessResponse<T> {
  status: "success";
  data: T;
}

export interface GasErrorResponse {
  status: "error";
  data: ErrorResponseData;
}

export interface ErrorResponseData {
  message: string;
  requestParams: Record<string, string>;
  postData?: AppsScriptHttpRequestEventPostData;
  cause: string;
}

// NOTE: This must match the type defined in google-apps-script-events.d.ts, but we don't want to bring the entire
// GoogleAppsScript namespace into this project, since it is used in browser-side code.
export interface AppsScriptHttpRequestEventPostData {
  length: number;
  type: string;
  contents: string;
  name: string;
}

export type ReportProgressCallback = (progress: string) => void;

/**
 * Class that can be used to send requests to a a Google Apps Script API that was created with the implementation in
 * `common-apps-script/src/client-server` in the `google-apps-scripts` repo.
 */
export class GasApiClient {
  constructor(protected readonly baseUrl: string) {
    // Force connections to Google APIs to use HTTPS
    // This is necessary because the auth token must be sent in the query string and needs to be encrypted
    if (!baseUrl.startsWith("https://"))
      throw new Error(`Invalid base URL: ${baseUrl}. The URL must use the HTTPS protocol.`);

    // Ensure the URL points to a Google Apps Script project within our organization
    if (!baseUrl.startsWith("https://script.google.com/a/macros/sentisolutions.ca/s/"))
      throw new Error(
        `Invalid base URL: ${baseUrl}. The URL must point to a Google Apps Script project within our organization.`,
      );
  }

  /**
   * Register the request, execute it, poll for updates, and return the final result.
   *
   * @param reportProgressCallback A callback function that will be called with progress updates during the request execution.
   */
  public async doPollingRequestSaga<TResponse>(
    httpMethod: "get" | "post",
    requestParams: GasRequest,
    reportProgressCallback: ReportProgressCallback | undefined,
  ): Promise<TResponse> {
    // Register the request; The server returns a unique request ID
    const requestLabel = requestParams.method;
    console.info(`Starting '${requestLabel}' request with parameters: ${JSON.stringify(requestParams)}`);
    const requestId = await this.sendRequest<string>(requestParams, { method: httpMethod });
    console.info(`Successfully registered '${requestLabel}' request with id ${requestId}.`);

    // Start the request execution; do not await the result
    console.info(`Starting execution of '${requestLabel}' request with id ${requestId}.`);
    void this.sendPostRequest({
      method: "executePollingRequest",
      requestId,
    });

    // Poll for the execution result (every 5 seconds)
    return new GasApiRequestPoller<TResponse>(
      this,
      reportProgressCallback,
      requestLabel,
      requestId,
      5000,
    ).pollForResult();
  }

  /**
   * Send a GET request to a GAS server project and return the response.
   */
  public sendGetRequest<TResponse>(
    queryParameters: GasRequest,
    fetchOptions?: Omit<RequestInit, "method">,
  ): Promise<TResponse> {
    return this.sendRequest<TResponse>(queryParameters, {
      ...fetchOptions,
      method: "get",
    });
  }

  /**
   * Send a POST request to a GAS server project and return the response.
   */
  public sendPostRequest<TResponse>(
    queryParameters: GasRequest,
    fetchOptions?: Omit<RequestInit, "method">,
  ): Promise<TResponse> {
    return this.sendRequest<TResponse>(queryParameters, {
      ...fetchOptions,
      method: "post",
    });
  }

  /**
   * Send a GET or POST request to a GAS server project and return the response.
   */
  protected async sendRequest<TResponse>(queryParameters: GasRequest, fetchOptions: RequestInit): Promise<TResponse> {
    // Do request
    const httpResponse = await this.sendHttpRequest(queryParameters, fetchOptions);

    // Get the response text
    const responseText = await httpResponse.text();

    // Validate response code
    const methodLabel = queryParameters.method;
    const responseCode = httpResponse.status;
    if (responseCode !== 200) {
      const errorBaseMessage = `'${methodLabel}' request failed with response code ${responseCode.toFixed()}`;
      console.error(`'${errorBaseMessage}: ${responseText}`);
      throw new GasApiResponseValidationError(
        `'${errorBaseMessage}. See the log for the full response content`,
        httpResponse,
      );
    }

    // Parse response body
    let parsedResponse;
    try {
      parsedResponse = JSON.parse(responseText) as GasResponse<TResponse>;
    } catch (err) {
      const errorBaseMessage = `Failed to parse JSON response for '${methodLabel}' request`;
      console.error(`${errorBaseMessage}: ${responseText}`);
      throw new GasApiResponseValidationError(
        `${errorBaseMessage}. See the log for the full response text`,
        httpResponse,
        { cause: err as Error },
      );
    }

    // Validate response body
    GasApiClient.validateResponseMessage(methodLabel, parsedResponse);

    // Return parsed response
    return parsedResponse.data;
  }

  private async sendHttpRequest(queryParameters: GasRequest, httpRequestOptions: RequestInit): Promise<Response> {
    // Get an access token for the request
    // NOTE: This will throw if the user is not authorized
    const { accessToken } = await googleAuthorizationTokenFetcher.requestAccessToken();

    // Add an authentication query parameter. This is necessary because it is impossible to authenticate to a GAS server
    // using fetch() and an Authorization header. Attempts to authenticate with an Authorization header always trigger
    // (and subsequently fail) CORS preflight checks, which are not supported by Google
    const queryParametersWithAuth = { ...queryParameters, access_token: accessToken };

    // URL-encode the search parameters
    const urlEncodedParams = this.urlEncodeParams(queryParametersWithAuth);

    // Generate the URL
    const url = `${this.baseUrl}?${urlEncodedParams}`;

    // Send the request
    return await fetch(url, httpRequestOptions);
  }

  private urlEncodeParams(requestParams: GasRequest): string {
    const urlEncodedParams = Object.entries(requestParams)
      .filter(([_key, value]) => !!value)
      .map(([key, value]) => [key, encodeURIComponent(value!)]);
    return new URLSearchParams(urlEncodedParams).toString();
  }

  public static validateResponseMessage<TResponse>(
    methodLabel: string,
    response: GasResponse<TResponse>,
  ): asserts response is GasSuccessResponse<TResponse> {
    if (response.status !== "success") {
      const errorDetails = response.data;
      throw new Error(`'${methodLabel}' request failed: ${errorDetails.message} -> ${errorDetails.cause}`);
    }
  }
}

export class GasApiResponseValidationError extends Error {
  constructor(
    message: string,
    public readonly response: Response,
    options?: ErrorOptions,
  ) {
    super(message, options);
  }
}
