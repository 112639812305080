import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";

import { ErrorBoundary } from "components/ErrorBoundary";
import { SalesOrderTrackerNav } from "./SalesOrderTrackerNav";
import { withJob, WithJobProps } from "./components/withJob";

const SalesOrderTrackerLayout: React.FC<WithJobProps> = ({ job }) => {
  return (
    <div className="d-flex h-100">
      <div id="sales-order-tracker-side-nav" className="bg-secondary-subtle py-3 me-3">
        <SalesOrderTrackerNav job={job} />
      </div>
      <Container fluid className="flex-grow-1 overflow-y-auto py-3">
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Container>
    </div>
  );
};

export default withJob(SalesOrderTrackerLayout);
