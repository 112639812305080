import { Container } from "react-bootstrap";
import { Link } from "react-router";

export const NotFound: React.FC = () => (
  <Container className="mt-5">
    <h1>404 - Not Found</h1>
    <p>
      The requested resource cannot be found. You can try to navigate back in your browser, or return{" "}
      <Link to="/">home</Link>.
    </p>
  </Container>
);
