import { AsyncReturnType, IterableElement } from "type-fest";
import { supabase } from "lib/supabase";
import { Job } from "../job-by-name";

export type SalesOrder = NonNullable<AsyncReturnType<typeof getSalesOrderQuery>["data"]>;
export type SalesOrderLineItem = IterableElement<SalesOrder["lines"]>;

const getSalesOrderQuery = async (salesOrderNumber: string) =>
  await supabase
    .from("SalesOrder")
    .select(
      `
      *,
      customer:Customer!inner(id,name),
      lines:SalesOrderLineItem(
        *,
        item:Item!inner(*),
        job:Job!inner(id, name)
      )
    `,
    )
    .eq("number", salesOrderNumber)
    .throwOnError()
    .limit(1)
    .single();

export async function getSalesOrder(salesOrderNumber: string): Promise<SalesOrder> {
  const salesOrder = (await getSalesOrderQuery(salesOrderNumber)).data;
  if (!salesOrder) throw new Error(`Failed to find a sales order with number ${salesOrderNumber}`);
  return salesOrder;
}

export function getSalesOrderLines(salesOrder: SalesOrder, job: Job): SalesOrderLineItem[] {
  return salesOrder.lines.filter((line) => line.jobId === job.id);
}
