import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ReactNode } from "react";

import { FormatDate } from "components/Date";
import { SosEntityLink } from "components/SosEntityLink";
import { SosCustomFields } from "utils/sos/custom-fields";
import { SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { SalesOrderLineItem } from "../queries/tracking-data/sales-orders";
import { LineItemDescription } from "./LineItemDescription";
import { withSalesOrderTrackingData } from "./withSalesOrderTrackingData";
import { DataTable } from "components/DataTable";

interface TableLineItem extends SalesOrderLineItem {
  wrongJob: boolean;
}

const columnHelper = createColumnHelper<TableLineItem>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columns: ColumnDef<TableLineItem, any>[] = [
  columnHelper.accessor("lineNumber", {
    header: "#",
    cell: ({ row, getValue }) => (
      <>
        {row.original.wrongJob ? "⚠️" : ""}
        {getValue()}
      </>
    ),
  }),
  columnHelper.accessor("item.name", {
    header: "Item",
    cell: ({ row }) => <SosEntityLink type="item" id={row.original.item.id} label={row.original.item.name} />,
  }),
  columnHelper.accessor("description", {
    header: "Description",
    cell: ({ row }) => <LineItemDescription line={row.original} />,
  }),
  columnHelper.accessor("quantity", { header: "Quantity" }),
  columnHelper.accessor("job.name", {
    header: "Job",
    cell: ({ row, getValue }) => (
      <>
        {row.original.wrongJob ? "⚠️" : ""}
        {getValue()}
      </>
    ),
  }),
];

const SalesOrderDetails: React.FC<SalesOrderTrackingData> = ({ job, salesOrder }) => {
  const evolvedOpportunityNumbers = [
    SosCustomFields.getNumberValue(job, "evolvedOpportunityNumber"),
    SosCustomFields.getNumberValue(job, "serviceEstimateOpportunityNumber"),
  ]
    .filter((x): x is number => !!x)
    .map((x) => `GNM#${x.toFixed()}`)
    .join(", ");

  const orderDetails: [string, ReactNode | undefined | null][] = [
    ["Job #", <SosEntityLink type="job" id={job.id} label={job.number} />],
    ["Sales Order #", <SosEntityLink type="salesorder" id={salesOrder.id} label={salesOrder.number} />],
    ["Evolved Opportunity #s", evolvedOpportunityNumbers],
    ["Date Opened", <FormatDate date={SosCustomFields.getDateValue(job, "dateOpened")} />],
    ["Date Closed", <FormatDate date={SosCustomFields.getDateValue(job, "dateClosed")} nullishValue="N/A" />],
    ["Customer", <SosEntityLink type="customer" id={salesOrder.customer.id} label={salesOrder.customer.name} />],
    ["Model", SosCustomFields.getStringValue(job, "model")],
    ["Pressure System", SosCustomFields.getBoolValue(job, "pressureSystem") ? "✅" : "❌"],
    ["Electrical Designer", SosCustomFields.getStringValue(job, "electricalDesigner") || "N/A"],
    ["Mechanical Designer", SosCustomFields.getStringValue(job, "mechanicalDesigner") || "N/A"],
  ];

  const lines = salesOrder.lines.map<TableLineItem>((line) => ({
    ...line,
    wrongJob: line.jobId !== job.id,
  }));

  return (
    <>
      <h2>Sales Order Details</h2>
      <dl className="row">
        {orderDetails.map(([label, value], index, array) => (
          <div key={label} className="col-12 col-md-6 col-lg-4 col-xl-3">
            <dt>{label}</dt>
            <dd className={index === array.length - 1 ? "m-0" : ""}>{value}</dd>
          </div>
        ))}
      </dl>
      <h3>Line Items</h3>
      <DataTable
        bordered
        hover
        columns={columns}
        data={lines}
        initialState={{ sorting: [{ id: "lineNumber", desc: false }] }}
      />
    </>
  );
};

export default withSalesOrderTrackingData(SalesOrderDetails, "Sales Order");
