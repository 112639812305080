import { BrowserRouter, Route, Routes } from "react-router";
import { withSentryReactRouterV7Routing } from "@sentry/react";

import { AppLayout } from "app/AppLayout";
import { Home } from "app/pages/Home";
import { Unauthenticated } from "app/pages/Unauthenticated";
import { ProtectedRoute } from "components/ProtectedRoute";
import { salesOrderTrackerRoutes } from "features/sales-order-tracking/SalesOrderTrackerRoutes";
import { NotFound } from "./pages/NotFound";
import { SalesOrderLog } from "features/sales-orders/SalesOrderLog";

// See: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/v7/#usage-with-routes--component
const SentryRoutes = withSentryReactRouterV7Routing(Routes);

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <SentryRoutes>
      <Route element={<AppLayout />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path="sales-orders" element={<SalesOrderLog />} />
          {salesOrderTrackerRoutes}
        </Route>
        <Route path="unauthenticated" element={<Unauthenticated />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </SentryRoutes>
  </BrowserRouter>
);
