import { ColumnDef, ColumnMeta, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";

import { DataTable } from "components/DataTable";
import { FormatDate } from "components/Date";
import { ErrorSpan } from "components/ErrorSpan";
import { SosEntityLink } from "components/SosEntityLink";
import { formatDineroCad } from "utils/dinero-util";
import { SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { CostTaxBreakdown } from "../queries/tracking-data/cost-tax-breakdown";
import {
  ItemReceipt,
  ItemReceiptLineItem,
  LinkedPoDetails,
  ReceivingCost,
} from "../queries/tracking-data/item-receipts";
import { ItemSelectResult } from "../queries/tracking-data/items";
import { getCostTaxBreakdownColumnDefs } from "./CostTaxBreakdownColumns";
import { LineItemDescription } from "./LineItemDescription";
import { withSalesOrderTrackingData } from "./withSalesOrderTrackingData";

type TableRow = LineItemTableRow | ReceivingCostTableRow;

interface TableRowBase {
  receipt: ItemReceipt;
  normalizedLine: NormalizedLineItem;
}

interface LineItemTableRow extends TableRowBase {
  lineItem: ItemReceiptLineItem;
}

interface ReceivingCostTableRow extends TableRowBase {
  receivingCost: ReceivingCost;
}

/**
 * This represents either an ItemReceiptLineItem, or a ReceivingCost line item normalized to include the data for the table
 */
interface NormalizedLineItem {
  id: number;
  lineNumber: number | string;
  item: ItemSelectResult;
  Description: React.FC;
  quantity: string;
  unitCost: string;
  totalCost: CostTaxBreakdown;
  LinkedPo: React.FC;
}

function normalizeLineItem(lineItem: ItemReceiptLineItem): NormalizedLineItem {
  const { id, lineNumber, item, quantity, unitCost, totalCost, linkedPo } = lineItem;
  return {
    id,
    lineNumber,
    item,
    Description: () => <LineItemDescription line={lineItem} />,
    quantity: quantity.toLocaleString(undefined, { maximumFractionDigits: 5 }),
    unitCost: formatDineroCad(unitCost.subtotal),
    totalCost,
    LinkedPo: () => (linkedPo ? <SosEntityLink type="purchaseorder" id={linkedPo.id} label={linkedPo.number} /> : null),
  };
}

function normalizeReceivingCost(receivingCost: ReceivingCost, receipt: ItemReceipt): NormalizedLineItem {
  const { id, lineNumber, item, vendor, jobAmountCad } = receivingCost;
  const jobPercent = receipt.receivingCostsJobPercent;
  const vendorLabel = vendor ? `(${vendor.name})` : <ErrorSpan>⚠ Vendor Missing</ErrorSpan>;
  const Description = () => (
    <>
      Other Costs ({(jobPercent * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}% allocated to this job):{" "}
      {item.description} {vendorLabel}
    </>
  );
  return {
    id,
    lineNumber: receipt.allLines.length + lineNumber,
    item,
    Description,
    quantity: "N/A",
    unitCost: "N/A",
    totalCost: jobAmountCad,
    LinkedPo: () => "N/A",
  };
}

const columnHelper = createColumnHelper<TableRow>();

// For receipt columns, set the rowspan of each cell on the on the first line to the total number of lines. Hide all subsequent rows for the same receipt
const receiptColCellProps: ColumnMeta<TableRow, unknown>["cellProps"] = ({ row }) => {
  const { receipt } = row.original;
  const lineItem = "lineItem" in row.original ? row.original.lineItem : undefined;
  const firstLineId = receipt.jobLines[0]!.id;
  const isFirstLine = lineItem && lineItem.id === firstLineId;
  return isFirstLine ?
      { rowSpan: receipt.jobLines.length + receipt.jobReceivingCosts.length }
    : { className: "d-none" };
};

const columns = [
  columnHelper.group({
    header: "Item Receipts",
    columns: [
      columnHelper.accessor("receipt.number", {
        header: "Item Receipt #",
        cell: ({
          row: {
            original: { receipt },
          },
        }) => <SosEntityLink type="itemreceipt" id={receipt.id} label={receipt.number} />,
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.date", {
        header: "Date",
        cell: ({ getValue }) => <FormatDate date={getValue()} />,
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.vendor.name", { header: "Vendor", meta: { cellProps: receiptColCellProps } }),
      columnHelper.accessor("receipt.vendorReceiptNum", {
        header: "Vendor #",
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor<"receipt.linkedPos", LinkedPoDetails[]>("receipt.linkedPos", {
        header: "Linked POs",
        cell: ({ getValue }) => {
          getValue().map((po, index) => (
            <Fragment key={po.id}>
              {index > 0 && ", "}
              <SosEntityLink type="purchaseorder" id={po.id} label={po.number} />
            </Fragment>
          ));
        },
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.currency.code", {
        header: "Currency",
        cell: ({
          row: {
            original: { receipt },
          },
        }) => (
          <>
            {receipt.currency.code}
            {receipt.vendor.currency && receipt.currency.code !== receipt.vendor.currency.code && (
              <ErrorSpan>⚠ Mismatch With Vendor</ErrorSpan>
            )}
          </>
        ),
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.exchangeRate", {
        header: "Exchange Rate",
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.jobLinesSummary.categorizedCosts.material.subtotal", {
        header: "Material Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.jobLinesSummary.categorizedCosts.nonMaterial.subtotal", {
        header: "Non-Material Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: receiptColCellProps },
      }),
      columnHelper.accessor("receipt.jobLinesSummary.categorizedCosts.shipping.subtotal", {
        header: "Shipping Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: receiptColCellProps },
      }),
      ...getCostTaxBreakdownColumnDefs<TableRow>((row) => row.receipt.jobLinesSummary.cost, {
        idPrefix: "receipt_jobLinesSummary_cost",
        cellProps: receiptColCellProps,
      }),
    ],
  }),
  columnHelper.group({
    header: "Line Items",
    columns: [
      columnHelper.accessor("normalizedLine.lineNumber", {
        header: "Line #",
        enableSorting: false,
        meta: {
          headerCellProps: () => ({ className: "border-start-double" }),
          cellProps: () => ({ className: "border-start-double" }),
        },
      }),
      columnHelper.accessor("normalizedLine.item.name", { header: "Item", enableSorting: false }),
      columnHelper.accessor("normalizedLine.item.type", { header: "Type", enableSorting: false }),
      columnHelper.accessor<"normalizedLine.Description", React.FC>("normalizedLine.Description", {
        header: "Description",
        enableSorting: false,
        cell: ({ getValue }) => {
          const Description = getValue();
          return <Description />;
        },
      }),
      columnHelper.accessor("normalizedLine.quantity", { header: "Quantity", enableSorting: false }),
      columnHelper.accessor("normalizedLine.unitCost", { header: "Unit Cost", enableSorting: false }),
      ...getCostTaxBreakdownColumnDefs<TableRow>((row) => row.normalizedLine.totalCost, {
        idPrefix: "normalizedLine_totalCost",
        enableSorting: false,
      }),
      columnHelper.accessor<"normalizedLine.LinkedPo", React.FC>("normalizedLine.LinkedPo", {
        header: "Linked PO",
        enableSorting: false,
        cell: ({ getValue }) => {
          const LinkedPo = getValue();
          return <LinkedPo />;
        },
      }),
    ],
  }),
] as const satisfies ColumnDef<TableRow>[];

const pricingDetailColumnIds = new Set([
  "receipt_currency_code",
  "receipt_exchangeRate",
  "receipt_jobLinesSummary_categorizedCosts_material_subtotal",
  "receipt_jobLinesSummary_categorizedCosts_nonMaterial_subtotal",
  "receipt_jobLinesSummary_categorizedCosts_shipping_subtotal",
  "normalizedLine_totalCost_tax",
  "normalizedLine_totalCost_total",
]);
const lineItemColumnIds = new Set([
  "normalizedLine_lineNumber",
  "normalizedLine_item_name",
  "normalizedLine_item_type",
  "normalizedLine_Description",
  "normalizedLine_quantity",
  "normalizedLine_unitCost",
  "normalizedLine_totalCost_subtotal",
  "normalizedLine_totalCost_tax",
  "normalizedLine_totalCost_total",
  "normalizedLine_LinkedPo",
]);

const LandedCosts: React.FC<SalesOrderTrackingData> = ({ itemReceipts }) => {
  // Define state
  const [showLineItems, setShowLineItems] = useState(true);
  const [showPricingDetails, setShowPricingDetails] = useState(false);

  // Define callbacks
  const toggleShowLineItems = useCallback(() => {
    setShowLineItems(!showLineItems);
  }, [showLineItems]);
  const toggleShowPricingDetails = useCallback(() => {
    setShowPricingDetails(!showPricingDetails);
  }, [showPricingDetails]);

  // Determine the table column's visibility
  // For each column, it is only visible if ALL the associated filters are set
  const allToggleableColumnIds = new Set([...pricingDetailColumnIds, ...lineItemColumnIds]);
  const columnVisibility = Object.fromEntries(
    [...allToggleableColumnIds].map((columnId) => [
      columnId,
      (!pricingDetailColumnIds.has(columnId) || showPricingDetails) &&
        (!lineItemColumnIds.has(columnId) || showLineItems),
    ]),
  );

  // Flatten the list of item receipts into a list of table rows (one row for each line item and receiving cost)
  const data = itemReceipts.flatMap((receipt) => [
    ...receipt.jobLines.map<LineItemTableRow>((lineItem) => ({
      receipt,
      lineItem,
      normalizedLine: normalizeLineItem(lineItem),
    })),
    ...receipt.jobReceivingCosts.map<ReceivingCostTableRow>((receivingCost) => ({
      receipt,
      receivingCost,
      normalizedLine: normalizeReceivingCost(receivingCost, receipt),
    })),
  ]);

  return (
    <>
      <h2>Landed Costs (Item Receipts)</h2>
      <div className="overflow-hidden mb-3">
        <div className="row row-cols-auto g-4 align-items-center">
          <span>Filters:</span>
          <Form.Check type="switch" label="Show Line Items" checked={showLineItems} onChange={toggleShowLineItems} />
          <Form.Check
            type="switch"
            label="Show Pricing Details"
            checked={showPricingDetails}
            onChange={toggleShowPricingDetails}
          />
        </div>
      </div>
      <DataTable
        bordered
        hover
        columns={columns}
        data={data}
        state={{ columnVisibility }}
        initialState={{
          sorting: [{ id: "receipt_date", desc: true }],
        }}
      />
    </>
  );
};

export default withSalesOrderTrackingData(LandedCosts, "Landed Costs");
