import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router";

import { PageLoader } from "components/PageLoader";
import { getJobByNameQueryOptions, Job } from "../queries/job-by-name";

export type SalesOrderTrackerRouteParams = "jobNumber";

export interface WithJobProps {
  job: Job;
}

/**
 * Higher-Order Component to fetch the selected job for the current navigation route. A loading screen is displayed while
 * the job is loading, then the job is passed to the wrapped component.
 */
export const withJob = (WrappedComponent: React.FC<WithJobProps>) => {
  return (props: object) => {
    const jobNumber = useParams<SalesOrderTrackerRouteParams>().jobNumber!;
    const jobQuery = useQuery(getJobByNameQueryOptions(jobNumber));

    if (jobQuery.isError) return `Error loading job: ${jobQuery.error.message}`;
    if (jobQuery.isPending) return <PageLoader text="Loading job" />;

    return <WrappedComponent job={jobQuery.data} {...props} />;
  };
};
