import { createContext, ReactNode } from "react";

import { GasApiClient } from "utils/gas-api-client";

const baseUrl =
  process.env.NODE_ENV === "production" ?
    "https://script.google.com/a/macros/sentisolutions.ca/s/AKfycbz-adMQiJKBzXEE9ZtcoKqIT9xT-ilz3VhFiZldOLgrK9Pr2k3bu-jM0lb-pN1sMliubg/exec"
  : "https://script.google.com/a/macros/sentisolutions.ca/s/AKfycbwMJySxx472-EM3HAn_pLONhlh4OdIoZOVF3S3lcKbQ/dev";

export const GasApiContext = createContext<GasApiClient>(null as unknown as GasApiClient);

export const GasApiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const appsScriptApi = new GasApiClient(baseUrl);
  return <GasApiContext.Provider value={appsScriptApi}>{children}</GasApiContext.Provider>;
};
