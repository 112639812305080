import { ReactNode } from "react";

export interface FormatDateProps {
  /**
   * A `Date` object, or a string that can be converted to a `Date` object using the `Date` constructor. String values
   * passed in should be in ISO 8601 format for consistent parsing.
   */
  date: string | Date | null | undefined;

  /** The value to render if `date` is `null` or `undefined`. Default to an empty string. */
  nullishValue?: ReactNode;
}

export const FormatDate: React.FC<FormatDateProps> = ({ date: dateOrString, nullishValue }) => {
  const date = typeof dateOrString === "string" ? new Date(dateOrString) : dateOrString;
  return date ? new Date(date).toISOString().split("T")[0] : (nullishValue ?? "");
};
