import { Outlet } from "react-router";

import { TopNav } from "app/nav/TopNav";

export const AppLayout: React.FC = () => {
  return (
    <div className="vh-100 d-flex flex-column">
      <TopNav />
      <div className="flex-grow-1 minh-0">
        <Outlet />
      </div>
    </div>
  );
};
