import { EntityWithUrl, getSosEntityUrl } from "utils/sos/utilities";

export interface SosEntityLinkProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, "id" | "children" | "href"> {
  type: EntityWithUrl;
  id: number;
  label: React.ReactNode;
}

export const SosEntityLink: React.FC<SosEntityLinkProps> = ({ type, id, label, ...anchorProps }) => {
  const href = getSosEntityUrl(type, id);
  return (
    <a href={href} {...anchorProps}>
      {label}
    </a>
  );
};
