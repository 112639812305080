import { useState } from "react";
import { Table } from "react-bootstrap";

import { ChildToggleButton } from "components/ChildToggleButton";
import { hasBom } from "utils/sos/utilities";
import { SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { BomNode } from "../queries/tracking-data/raw-bom";
import { withSalesOrderTrackingData } from "./withSalesOrderTrackingData";
import { errorTextClass } from "components/ErrorSpan";

const BomHierarchy: React.FC<SalesOrderTrackingData> = ({ rawBomHierarchy }) => {
  return (
    <>
      <h2>BOM Hierarchy</h2>
      <Table bordered hover className="table-hierarchical">
        <thead>
          <tr>
            <th>Item</th>
            <th>Type</th>
            <th>Description</th>
            <th>Preferred Vendor</th>
            <th>Quantity</th>
            <th>Build Notes</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {rawBomHierarchy.map((node) => (
            <RowsForHierarchyNode key={node.lineNumber} node={node} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

const RowsForHierarchyNode: React.FC<{ node: BomNode }> = ({ node }) => {
  const [showChildren, setShowChildren] = useState(true);
  const itemHasBom = hasBom(node.item.type);
  const children = itemHasBom ? node.children : null;
  const missingVendor = itemHasBom ? false : !node.item.preferredVendor;
  return (
    <>
      <tr className={`tree-depth-${node.recursiveDepth.toFixed()}`}>
        <td>
          <pre className="m-0">
            {node.asciiTreePrefix}
            {node.item.name}{" "}
            <ChildToggleButton children={children} showChildren={showChildren} setShowChildren={setShowChildren} />
          </pre>
        </td>
        <td>{node.item.type}</td>
        <td>{node.item.description}</td>
        <td className={missingVendor ? errorTextClass : ""}>
          {missingVendor ? "⚠ No preferred vendor selected" : node.item.preferredVendor?.name}
        </td>
        <td>{node.quantity.toLocaleString(undefined, { maximumFractionDigits: 5 })}</td>
        <td>{node.notes}</td>
      </tr>
      {children && showChildren ?
        children.map((child, index) => <RowsForHierarchyNode key={index} node={child} />)
      : null}
    </>
  );
};

export default withSalesOrderTrackingData(BomHierarchy, "BOM Hierarchy");
