import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect } from "react";
import { Dropdown, NavDropdown, Nav, Spinner } from "react-bootstrap";
import TimeAgo from "react-timeago";

import { Icon } from "components/Icon";
import { subscribeToSyncMetadataChanges, syncMetadataQueryOptions } from "queries/sync-metadata";
import { GasApiContext } from "stores/GasApiContext";

export const SyncStatusNavDropDown: React.FC<{ className?: string }> = ({ className }) => {
  const [syncRunning, setSyncRunning] = React.useState(false);

  // Fetch sync metadata and subscribe to changes
  const { isPending, isError, data: syncMetadata, error } = useQuery(syncMetadataQueryOptions);
  const queryClient = useQueryClient();
  useEffect(() => {
    const subscription = subscribeToSyncMetadataChanges(queryClient);
    return () => void subscription.unsubscribe();
  });

  if (isError) return <LoadingNavItem>Error loading sync data: {error.message}</LoadingNavItem>;
  if (isPending) return <LoadingNavItem>Loading sync data...</LoadingNavItem>;
  if (!syncMetadata.length) return <LoadingNavItem>"ERROR: No sync data found"</LoadingNavItem>;

  const title = (
    <>
      {syncRunning ?
        <Spinner size="sm" />
      : <SyncAlert lastUpdated={syncMetadata[0]!.lastUpdated} />}{" "}
      Sync
    </>
  );

  return (
    <NavDropdown title={title} className={"fixed-height " + (className ?? "")} autoClose="outside" align="end">
      <SyncNowButton {...{ syncRunning, setSyncRunning }} />
      <Dropdown.Divider />
      {syncMetadata.map((sync) => (
        <Dropdown.ItemText key={sync.entityType} className="text-wrap text-md-nowrap">
          <b>{sync.entityType}:</b> <SyncTime lastUpdated={sync.lastUpdated} />
        </Dropdown.ItemText>
      ))}
    </NavDropdown>
  );
};

const LoadingNavItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Nav.Item>
    <Nav.Link disabled={true}>{children}</Nav.Link>
  </Nav.Item>
);

const SyncTime: React.FC<{ lastUpdated: string | null }> = ({ lastUpdated }) => {
  if (!lastUpdated) return <>never synced</>;
  const date = new Date(lastUpdated);
  return (
    <>
      <SyncAlert lastUpdated={lastUpdated} />
      <TimeAgo date={date} />
    </>
  );
};

const SyncAlert: React.FC<{ lastUpdated: string | null }> = ({ lastUpdated }) => {
  if (!lastUpdated) return null;
  const date = new Date(lastUpdated);
  const showAlert = date.getTime() < Date.now() - 1000 * 60 * 60 * 24; // 24 hours
  return showAlert ? <span className="align-text-bottom">⚠</span> : null;
};

const SyncNowButton: React.FC<{ syncRunning: boolean; setSyncRunning: (value: boolean) => void }> = ({
  syncRunning,
  setSyncRunning,
}) => {
  const appsScriptApi = useContext(GasApiContext);
  // const [syncRunning, setSyncRunning] = React.useState(false);
  const [syncStatus, setSyncStatus] = React.useState("");
  const [syncError, setSyncError] = React.useState<Error | null>(null);

  const onClick = useCallback(() => {
    if (syncRunning) return;
    setSyncRunning(true);
    setSyncError(null);
    setSyncStatus("Initializing sync...");
    void appsScriptApi
      .doPollingRequestSaga("get", { method: "syncAllEntities" }, setSyncStatus)
      .catch((err: unknown) => {
        setSyncError(err as Error);
      })
      .finally(() => {
        setSyncRunning(false);
      });
  }, [appsScriptApi, syncRunning]);

  const icon =
    syncRunning ? <Spinner className="me-3" />
    : syncError ? <Icon icon="exclamation-circle-fill" className="col-auto mt-1 me-1 text-danger" />
    : <Icon icon="arrow-repeat" className="col-auto mt-1 me-1" />;

  const label = syncRunning ? "Sync Running" : "Sync Now";

  return (
    <Dropdown.Item onClick={onClick} className="text-wrap text-md-nowrap" disabled={syncRunning}>
      <div className="d-flex align-items-center">
        {icon}
        <span>
          {label}
          {syncStatus && (
            <span className="text-muted">
              <br />
              {syncStatus}
            </span>
          )}
        </span>
      </div>
      {syncError && (
        <>
          <br />
          <span className="text-danger text-wrap">Previous sync failed: {syncError.message}</span>
        </>
      )}
    </Dropdown.Item>
  );
};
