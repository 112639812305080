import { QueryData } from "@supabase/supabase-js";
import { queryOptions } from "@tanstack/react-query";
import { IterableElement } from "type-fest";

import { supabase } from "lib/supabase";

export type SalesOrderJob = IterableElement<QueryData<typeof salesOrderJobsQuery>>;

const salesOrderJobsQuery = supabase
  .from("Job")
  .select("*, customer:Customer!inner(id,name,billing), customFieldValues:JobCustomFieldValue(*)")
  .eq("deleted", false)
  .like("number", "SO-____")
  .order("number", { ascending: false })
  .throwOnError();

export const salesOrderJobsQueryOptions = queryOptions({
  queryKey: ["Job", "sales-orders"],
  queryFn: async () => (await salesOrderJobsQuery).data,
});
