import { supabase } from "lib/supabase";

export interface TokenResult {
  accessToken: string;
  scope: string;
  expiry: number;
}

type TokenResponse = google.accounts.oauth2.TokenResponse;
type TokenClientConfig = google.accounts.oauth2.OverridableTokenClientConfig;

/**
 * This class exposes the `requestAccessToken` method to request an access token from Google that can be used to
 * authorize against the specified scopes.
 *
 * The resulting token is cached, and will be reused until it expires. If the token is expired, an authorization flow
 * will be initiated to request a new token.
 *
 * This class uses the "Google 3P Authorization" library and the 'implicit grant model' (also referred to as the 'token'
 * model) to request an access token from Google
 *
 * See: https://developers.google.com/identity/oauth2/web/reference/js-reference
 * and: https://developers.google.com/identity/oauth2/web/guides/use-token-model
 *
 * The implicit grant model is a simplified authorization flow that does not require a client secret. It is
 * recommended for public clients, such as JavaScript applications, where the client secret cannot be securely stored.
 * The downside of this model is that the token has a shorter lifetime (typically 1 hour) and must be refreshed
 * more frequently.
 *
 * NOTE: This token is an authorization token, which is different from the 'id' authentication token returned by the
 * Google sign in button and one-tap sign in flow. The 'id' token is used to authenticate the user and only requests the
 * 'openid', 'profile', and 'email' scopes. The authorization token requested here can be requested on-demand after sign
 * in and can be used to request additional scopes, as needed
 */
class GoogleAuthorizationTokenFetcher {
  private readonly tokenClient: google.accounts.oauth2.TokenClient;

  private accessTokenPromise: Promise<TokenResult> | null = null;
  private resolve: ((result: TokenResult) => void) | null = null;
  private reject: ((reason: Error) => void) | null = null;

  private tokenResult: TokenResult | null = null;

  constructor(
    clientId: string,
    private readonly scopes: string[],
  ) {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: scopes.join(" "),
      callback: this.tokenCallback.bind(this),
      error_callback: this.tokenCallback.bind(this),
    });
  }

  public async requestAccessToken(config?: TokenClientConfig): Promise<TokenResult> {
    // Remove the existing token if it has expired
    if (this.tokenResult && Date.now() > this.tokenResult.expiry) {
      this.tokenResult = null;
    }

    // Return the existing token result, if any
    if (this.tokenResult) return this.tokenResult;

    // Get the current user's email address from the Supabase session that was created during authentication
    // This will allow the user to skip the user selection screen for this authorization step
    const session = await supabase.auth.getSession();
    const login_hint = session.data.session?.user.email;

    // Wait for the result of an existing token request, if any
    if (this.accessTokenPromise) return await this.accessTokenPromise;

    // Start a new token request and store the promise
    this.accessTokenPromise = new Promise<TokenResult>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.tokenClient.requestAccessToken({ login_hint, ...config });
    return await this.accessTokenPromise;
  }

  private tokenCallback(tokenResponse: TokenResponse | google.accounts.oauth2.ClientConfigError): void {
    // Throw an error if our state is somehow corrupted and we have no promise to resolve
    // This should never happen because the promise is set when the token request flow is started
    if (!this.resolve || !this.reject)
      throw new Error("Access token callback was called but there is no promise awaiting it");

    try {
      // Attempt to parse the token response and resolve the promise
      const tokenResult = this.getTokenResult(tokenResponse);
      this.resolve(tokenResult);
    } catch (cause) {
      // On error, reject the promise
      const error = new Error("Google authorization request failed -> " + (cause as Error).message, { cause });
      this.reject(error);
    } finally {
      // Reset the promise state to indicate that a new token request can be started
      this.accessTokenPromise = null;
      this.resolve = null;
      this.reject = null;
    }
  }

  private getTokenResult(tokenResponse: TokenResponse | google.accounts.oauth2.ClientConfigError): TokenResult {
    // Fail if a client-side error occurred during the token request (i.e. error_callback was invoked)
    if (tokenResponse instanceof Error) throw tokenResponse;

    // Fail if the token request failed
    if (tokenResponse.error) {
      console.error("Google authorization request failed: ", tokenResponse);
      throw new Error(tokenResponse.error_description);
    }

    // Fail if the user did not authorize all the requested scopes
    const grantedScopes = new Set(tokenResponse.scope.split(" "));
    const missingScopes = this.scopes.filter((scope) => !grantedScopes.has(scope)).join(", ");
    if (missingScopes.length)
      throw new Error("User did not authorize all requested permissions. Missing scopes: " + missingScopes);

    // Calculate the token expiry timestamp
    const expiresIn = parseInt(tokenResponse.expires_in, 10);
    const expiry = Date.now() + expiresIn * 1000;

    // Return the token result and cache it
    return (this.tokenResult = {
      accessToken: tokenResponse.access_token,
      scope: tokenResponse.scope,
      expiry,
    });
  }
}

// Export a singleton instance of the GoogleAuthorizationTokenFetcher class with all the scopes required for this application
export default new GoogleAuthorizationTokenFetcher(process.env.GOOGLE_OAUTH_CLIENT_ID, [
  "https://www.googleapis.com/auth/drive.readonly",
]);
