import { isMaterialCost } from "utils/sos/utilities";
import { CostTaxBreakdown } from "./cost-tax-breakdown";
import { ItemReceiptLineItemSelectResult, ReceivingCost } from "./item-receipts";
import { Item } from "./items";
import { PurchaseOrderLineItemSelectResult } from "./purchase-orders";
import { dineroCadFromFloatingPointForeignAmount, SupportedCurrencyCode } from "utils/dinero-util";

export interface PurchaseCostsSummary {
  /** The sum of the sub-total (i.e. pre-tax amount) for each line, grouped by the category assigned to each line */
  categorizedCosts: Record<CostCategory, CostTaxBreakdown>;
  /** The sum of the cost for all lines. */
  cost: CostTaxBreakdown;
}

export type CostCategory = "material" | "nonMaterial" | "shipping";

export type PurchasingLineItemSelectResult = PurchaseOrderLineItemSelectResult | ItemReceiptLineItemSelectResult;

export type ProcessedPurchasingLine<T extends PurchasingLineItemSelectResult> = Omit<T, "unitprice" | "amount"> &
  ProcessedPurchasingLineBase;

export interface ProcessedPurchasingLineBase {
  costCategory: CostCategory;
  unitCost: CostTaxBreakdown;
  totalCost: CostTaxBreakdown;
}

export function createLineSummaryForJob<TLine extends PurchasingLineItemSelectResult>(
  lines: TLine[],
  currencyCode: SupportedCurrencyCode,
  exchangeRate: number,
  jobId: number,
) {
  // Get all the lines for the specified job
  const linesForJob = lines.filter((x) => x.job && x.job.id === jobId);

  // Process each line
  const jobLines = linesForJob.map((line) => processPurchasingLineItem(line, currencyCode, exchangeRate));

  // Perform summary calculations on the lines
  const jobLinesSummary = generatePurchaseCostsSummary(jobLines);

  return { jobLines, jobLinesSummary };
}

export function processPurchasingLineItem<TLine extends PurchasingLineItemSelectResult>(
  line: TLine,
  currencyCode: SupportedCurrencyCode,
  exchangeRate: number,
): ProcessedPurchasingLine<TLine> {
  const costCategory = getItemCostCategory(line.item);
  const taxCode = line.taxCode;
  const unitPriceCad = dineroCadFromFloatingPointForeignAmount(line.unitprice, currencyCode, exchangeRate);
  const unitCost = new CostTaxBreakdown({ subtotal: unitPriceCad, taxCode });
  const amountCad = dineroCadFromFloatingPointForeignAmount(line.amount, currencyCode, exchangeRate);
  const totalCost = new CostTaxBreakdown({ subtotal: amountCad, taxCode });
  return {
    ...line,
    costCategory,
    unitCost,
    totalCost,
  };
}

/**
 * Function that can be used to generate a summary of the costs for either a set of purchasing lines or a set of
 * 'other costs' on an item receipt.
 */
export function generatePurchaseCostsSummary(
  lines: ProcessedPurchasingLineBase[] | ReceivingCost[],
): PurchaseCostsSummary {
  // Iterate through the line items to create sums for each cost category
  const categorizedCosts = lines.reduce<Record<CostCategory, CostTaxBreakdown>>(
    (sums, line) => {
      const lineCategory = line.costCategory;
      sums[lineCategory] = sums[lineCategory].add("jobAmountCad" in line ? line.jobAmountCad : line.totalCost);
      return sums;
    },
    { material: CostTaxBreakdown.ZERO, nonMaterial: CostTaxBreakdown.ZERO, shipping: CostTaxBreakdown.ZERO },
  );

  // Perform some additional summary calculations
  const cost = Object.values(categorizedCosts).reduce((sum, x) => sum.add(x), CostTaxBreakdown.ZERO);
  return { categorizedCosts, cost };
}

export function getItemCostCategory(item: Pick<Item, "id" | "type">): CostCategory {
  if (item.id === 775) return "shipping";
  return isMaterialCost(item.type) ? "material" : "nonMaterial";
}
