import { QueryClient, queryOptions } from "@tanstack/react-query";
import { supabase } from "lib/supabase";

import { Tables } from "types/supabase-types";

export type SyncMetadata = Tables<"SyncMetadata">;

const syncMetadataQuery = supabase
  .from("SyncMetadata")
  .select("*")
  .order("lastUpdated", { ascending: true })
  .throwOnError();

export const syncMetadataQueryOptions = queryOptions({
  queryKey: ["SyncMetadata", "all"],
  queryFn: async () => (await syncMetadataQuery).data,
});

export const subscribeToSyncMetadataChanges = (queryClient: QueryClient) => {
  return supabase
    .channel("SyncMetadataChanges")
    .on<Tables<"SyncMetadata">>(
      "postgres_changes",
      { event: "UPDATE", schema: "public", table: "SyncMetadata" },
      (newRecord) => {
        queryClient.setQueryData(["SyncMetadata", "all"], (oldData: SyncMetadata[]) => {
          const newData = structuredClone(oldData);
          const oldRecordIndex = newData.findIndex((x) => x.entityType === newRecord.new.entityType);

          if (oldRecordIndex !== -1) {
            newData[oldRecordIndex] = newRecord.new;
          } else {
            newData.push(newRecord.new);
          }

          return newData.sort((a, b) => a.lastUpdated.localeCompare(b.lastUpdated));
        });
      },
    )
    .subscribe();
};
