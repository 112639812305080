import { useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Icon } from "components/Icon";

export interface ChildToggleButtonProps {
  children: unknown[] | null;
  showChildren: boolean;
  setShowChildren: React.Dispatch<boolean>;
}

export const ChildToggleButton: React.FC<ChildToggleButtonProps> = ({ children, showChildren, setShowChildren }) => {
  // Define a click handler callback
  const toggleChildren = useCallback(() => {
    setShowChildren(!showChildren);
  }, [showChildren, setShowChildren]);

  // Render an invisible spacing placeholder for items that do not have a BOM
  if (!children)
    return (
      <Button className="p-0 invisible" disabled={true}>
        <Icon icon="plus-square" />
      </Button>
    );

  // Render an un-clickable button for items that support a BOM but do not have one
  if (!children.length) {
    return (
      <OverlayTrigger overlay={<Tooltip>No children found</Tooltip>}>
        <Button variant="link" className="p-0">
          <Icon icon="x-square" className="text-muted" />
        </Button>
      </OverlayTrigger>
    );
  }

  // Otherwise, render a toggle button
  return (
    <OverlayTrigger overlay={<Tooltip>{showChildren ? "Hide Children" : "Show Children"}</Tooltip>}>
      <Button variant="link" className="p-0" onClick={children.length ? toggleChildren : undefined}>
        <Icon icon={showChildren ? "dash-square" : "plus-square-fill"} />
      </Button>
    </OverlayTrigger>
  );
};
