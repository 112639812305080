import { GasApiClient, GasResponse } from "./gas-api-client";
import { ReportProgressCallback } from "./gas-api-client";
import { executeWithRetries } from "./retry-utilities";

export type RequestStatusResponse<T> =
  | RequestStatusPendingResponse
  | RequestStatusProcessingResponse
  | RequestStatusCompleteResponse<T>;

export interface RequestStatusResponseBase {
  status: RequestStatus;
  progressMessage: string | null;
}

export type RequestStatus = "pending" | "processing" | "completed";

export interface RequestStatusPendingResponse extends RequestStatusResponseBase {
  status: "pending";
}

export interface RequestStatusProcessingResponse extends RequestStatusResponseBase {
  status: "processing";
}

export interface RequestStatusCompleteResponse<T> extends RequestStatusResponseBase {
  status: "completed";
  data: GasResponse<T>;
}

/**
 * This class provides a recursive polling implementation for a long-running request to a Google Apps Script api.
 */
export class GasApiRequestPoller<TResponse> {
  private readonly initializationError;

  private currentProgressMessage = "";

  constructor(
    private readonly gasRequestClient: GasApiClient,
    private readonly reportProgressCallback: ReportProgressCallback | undefined,
    private readonly requestLabel: string,
    private readonly requestId: string,
    private readonly pollingIntervalMilliseconds = 5000,
  ) {
    this.initializationError = new Error(`'${this.requestLabel}' request did not initialize correctly`);
  }

  public async pollForResult(): Promise<TResponse> {
    // Start sending poll requests
    // NOTE: Use retry logic for the first request in order to handle the case where the request is still initializing
    return await executeWithRetries({
      fnLabel: "sendPollRequest",
      fn: this.sendPollRequest.bind(this),
      shouldRetry: (err) => (err === this.initializationError ? "Request still initializing" : false),
      maxRetries: 5,
    });
  }

  private async sendPollRequest(): Promise<TResponse> {
    // Send poll request
    const httpResponse = await this.gasRequestClient.sendGetRequest<RequestStatusResponse<TResponse>>({
      method: "pollingRequestStatus",
      requestId: this.requestId,
    });

    // Fail if the request is still in the 'pending' state (i.e. the execution has still not started since the 'executeRequest' request was sent)
    const { requestLabel, reportProgressCallback } = this;
    if (httpResponse.status === "pending") throw this.initializationError;

    // If the request is complete, validate then return the result
    if (httpResponse.status === "completed") {
      const responseMessage = httpResponse.data;
      GasApiClient.validateResponseMessage(requestLabel, responseMessage);
      return responseMessage.data;
    }

    // The request is not complete; report a progress update, if necessary
    if (httpResponse.progressMessage && httpResponse.progressMessage !== this.currentProgressMessage) {
      this.currentProgressMessage = httpResponse.progressMessage;
      console.info(`Received progress update for '${requestLabel}' request: ${this.currentProgressMessage}`);
      if (reportProgressCallback) reportProgressCallback(this.currentProgressMessage);
    }

    // Sleep for the polling interval
    await new Promise<void>((resolve) => {
      setTimeout(resolve, this.pollingIntervalMilliseconds);
    });

    // Recurse to Send another poll request
    // NOTE: This does NOT use retry logic, which is only needed for the first polling attempt while the request is initializing
    return await this.sendPollRequest();
  }
}
