import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import { supabase } from "lib/supabase";
import { Tables } from "types/supabase-types";
import { validateCurrencyCode } from "utils/dinero-util";
import { CostTaxBreakdown } from "./cost-tax-breakdown";
import { CADCurrencyConverter } from "./currency";
import { itemSelect, ItemSelectResult } from "./items";
import { PurchaseTaxCode, vendorPricingInformationSelect, VendorWithPricingInformation } from "./vendor";
import {
  CostCategory,
  createLineSummaryForJob,
  getItemCostCategory,
  ProcessedPurchasingLine,
  PurchaseCostsSummary,
} from "./purchasing-line-item";
import { SosCustomFields } from "utils/sos/custom-fields";

export interface ItemReceipt extends Omit<ItemReceiptSelectResult, "lines" | "receivingCosts"> {
  vendorReceiptNum: string | null;
  linkedPos: LinkedPoDetails[];
  allLines: ItemReceiptLineItemSelectResult[];
  jobLines: ItemReceiptLineItem[];
  jobLinesSummary: PurchaseCostsSummary;

  /**
   * The percentage of the receiving applied to the current job. The receiving costs are divided up equally between all
   * jobs on the item receipt. For example, if there are three jobs on the item receipt, they are each allocated 1/3 of
   * the receiving costs.
   */
  receivingCostsJobPercent: number;

  /**
   * The 'other costs' from the item receipt with the cost amounts converted to CAD and multiplied by the otherCostsJobPercent.
   */
  jobReceivingCosts: ReceivingCost[];
}

export interface ItemReceiptLineItem extends ProcessedPurchasingLine<ItemReceiptLineItemSelectResult> {
  linkedPo: LinkedPoDetails | undefined;
}

export interface ReceivingCost extends Omit<ReceivingCostSelectResult, "amount"> {
  costCategory: CostCategory;

  /**
   * The portion of this receiving cost allocated to the current job and converted to CAD. The tax rate used for this
   * comes from the selected vendor for the cost, falling back to the item's tax rate if the vendor does not have one.
   */
  jobAmountCad: CostTaxBreakdown;
}

// NOTE: This type is specified manually rather than automatically using `SupabaseSelectResult` because the select
// query is complex enough that the performance of the dynamic type crashes the TypeScript server.
interface ItemReceiptSelectResult extends Tables<"ItemReceipt"> {
  vendor: VendorWithPricingInformation;
  currency: Pick<Tables<"Currency">, "code">;
  customFieldValues: Tables<"ItemReceiptCustomFieldValue">[];
  lines: ItemReceiptLineItemSelectResult[];
  receivingCosts: ReceivingCostSelectResult[];
}

export interface ItemReceiptLineItemSelectResult
  extends Pick<
    Tables<"ItemReceiptLineItem">,
    "id" | "lineNumber" | "description" | "unitprice" | "amount" | "quantity"
  > {
  item: ItemSelectResult;
  taxCode: PurchaseTaxCode | null;
  job: Pick<Tables<"Job">, "id" | "name"> | null;
  linkedPurchaseOrderLineItem: LinkedPurchaseOrderLineItem | null;
}

interface LinkedPurchaseOrderLineItem {
  purchaseOrder: Pick<Tables<"PurchaseOrder">, "id" | "number">;
}

interface ReceivingCostSelectResult extends Tables<"ReceivingCost"> {
  item: ItemSelectResult;
  vendor: VendorWithPricingInformation | null;
}

export type LinkedPoDetails = NonNullable<
  ItemReceiptLineItemSelectResult["linkedPurchaseOrderLineItem"]
>["purchaseOrder"];

const itemReceiptSelect = `
  *,
  vendor:Vendor!inner(${vendorPricingInformationSelect}),
  currency:Currency!inner(code),
  customFieldValues:ItemReceiptCustomFieldValue(*),
  lines:ItemReceiptLineItem(
    id,
    lineNumber,
    description,
    unitprice,
    amount,
    quantity,
    item:Item!inner(${itemSelect}),
    taxCode:TaxCode!taxCodeId(id,name,purchaseTaxRate),
    job:Job!inner(id, name),
    linkedPurchaseOrderLineItem:PurchaseOrderLineItem(
      purchaseOrder:PurchaseOrder!inner(id, number)
    )
  ),
  receivingCosts:ReceivingCost(
    *,
    item:Item!inner(${itemSelect}),
    vendor:Vendor!inner(${vendorPricingInformationSelect})
  )
`;

export async function getItemReceipts(jobId: number, CADCurrencyConverter: CADCurrencyConverter) {
  // Fine all item receipts that have a line item linked to the job
  const jobMatchingReceiptIdsResponse = await supabase
    .from("ItemReceiptLineItem")
    .select("itemReceiptId")
    .eq("jobId", jobId)
    .throwOnError();
  const jobMatchingReceiptIds = uniq(jobMatchingReceiptIdsResponse.data.map((x) => x.itemReceiptId));

  // Fetch all the item receipts matching the ids
  const queryResult = await supabase
    .from("ItemReceipt")
    .select<typeof itemReceiptSelect, ItemReceiptSelectResult>(itemReceiptSelect)
    .in("id", jobMatchingReceiptIds)
    .order("date", { ascending: false })
    .order("lineNumber", { referencedTable: "lines", ascending: true })
    .order("lineNumber", { referencedTable: "receivingCosts", ascending: true })
    .throwOnError();

  // Process all the receipts before returning
  return queryResult.data.map((receipt) => processItemReceipt(receipt, jobId, CADCurrencyConverter));
}

function processItemReceipt(
  { lines: allLines, receivingCosts, ...receipt }: ItemReceiptSelectResult,
  jobId: number,
  todayExchangeRateConverter: CADCurrencyConverter,
): ItemReceipt {
  const vendorReceiptNum = SosCustomFields.getStringValue(receipt, "vendorReceiptNum");

  // Process the line items; Do some common processing first, then continue doing some additional processing specific to item receipts
  const currencyCode = validateCurrencyCode(receipt.currency.code);
  const { jobLines: preProcessedJobLines, jobLinesSummary } = createLineSummaryForJob(
    allLines,
    currencyCode,
    receipt.exchangeRate,
    jobId,
  );
  const jobLines = preProcessedJobLines.map(processItemReceiptLineItem);

  // Get a list of all linked POs from the processed line items
  const linkedPos = uniqBy(
    jobLines.map((line) => line.linkedPo).filter((x) => !!x),
    (x) => x.id,
  );

  // Process the receiving costs
  const numJobs = uniq(allLines.filter((x) => !!x.job?.id).map((x) => x.job!.id)).length;
  const receivingCostsJobPercent = 1 / numJobs;
  const jobReceivingCosts = receivingCosts.map((x) =>
    processReceivingCost(x, receipt, numJobs, todayExchangeRateConverter),
  );

  return {
    ...receipt,
    vendorReceiptNum,
    allLines,
    jobLines,
    jobLinesSummary,
    linkedPos,
    receivingCostsJobPercent,
    jobReceivingCosts,
  };
}

function processItemReceiptLineItem(
  line: ProcessedPurchasingLine<ItemReceiptLineItemSelectResult>,
): ItemReceiptLineItem {
  return { ...line, linkedPo: line.linkedPurchaseOrderLineItem?.purchaseOrder };
}

function processReceivingCost(
  cost: ReceivingCostSelectResult,
  receipt: Pick<ItemReceiptSelectResult, "vendor">,
  numJobs: number,
  todayExchangeRateConverter: CADCurrencyConverter,
): ReceivingCost {
  const costCategory = getItemCostCategory(cost.item);

  // Calculate the receiving cost in CAD with tax
  const amountCad = getReceivingCostAmount(cost, receipt, todayExchangeRateConverter);

  // Calculate the portion of the cost allocated to the current job
  const jobAmountCad = amountCad.allocate([1, numJobs])[0]!;

  return { ...cost, costCategory, jobAmountCad };
}

/**
 * Calculate the receiving cost in CAD with tax. This assumes that the cost is in the vendor's local currency, and the
 * value specified in SOS is the after-tax amount.
 */
function getReceivingCostAmount(
  cost: ReceivingCostSelectResult,
  _receipt: Pick<ItemReceiptSelectResult, "vendor">,
  _todayExchangeRateConverter: CADCurrencyConverter,
): CostTaxBreakdown {
  // Return an error amount if no vendor is specified on the cost
  if (!cost.vendor) return CostTaxBreakdown.withError("No vendor specified");

  return CostTaxBreakdown.withError(
    "There is a bug in SOS that causes other costs to be returned with incorrect values. This is a placeholder error message until the bug is fixed.",
  );

  // // Return an error if the vendor has no currency
  // if (!cost.vendor.currency) return CostTaxBreakdown.withError(`Vendor '${cost.vendor.name}' has no currency`);

  // if (valuefromapiisconvertedtoCad) {
  //   // Create a dinero object for the cost in CAD
  //   // We can do this because the SOS API automatically converts the cost entered in the UI in the vendor's currency to CAD for us
  //   const costAmountCad = dineroCADFromFloatingPoint(cost.amount);

  // } else if (valuefromapiisinoriginalvendorcurrency) {

  //    TODO: Use dineroCadFromFloatingPointForeignAmount() instead

  //   // Create a dinero object for the cost in the vendor's local currency
  //   const vendorCurrencyCode = getCurrencyCodeForVendor(cost.vendor);
  //   const costAmountLocal = dineroForCurrencyFromFloatingPoint(cost.amount, vendorCurrencyCode);
  //   console.info(
  //     `Parsed ${cost.amount.toString()} as ${toDecimal(costAmountLocal)} ${vendorCurrencyCode} for other cost ${cost.item.description ?? ""} (${cost.vendor.name})  on item receipt ${receipt.number}`,
  //   );

  //   // Decide on the exchange rate converter to use. If the cost vendor's currency matches the item receipt vendor's currency, use
  //   // the exchange rate saved on the item receipt. Otherwise, use the exchange rate for the vendor on the current date.
  //   // TODO: We should fall back to the vendor's exchange rate on the day the item receipt was created, not the current date.
  //   const cadConverter =
  //     cost.vendor.currency.code === receipt.vendor.currency?.code ?
  //       receiptExchangeRateConverter
  //     : todayExchangeRateConverter;

  //   // Convert the amount to CAD
  //   const costAmountCad = cadConverter(costAmountLocal);
  //   console.info(
  //     `Converted ${toDecimal(costAmountLocal)} ${vendorCurrencyCode} to ${toDecimal(costAmountCad)} CAD for other cost ${cost.item.description ?? ""} (${cost.vendor.name}) on item receipt ${receipt.number}`,
  //   );
  // }

  // // Create the full tax breakdown. The tax code from the vendor is used (do not fall back to the tax code on the item).
  // // TODO: We may need to revisit this, it's not clear whether the amount entered on item receipts includes tax or not. It may not actually be consistent at all
  // const taxCode = cost.vendor.taxCode;
  // return new CostTaxBreakdown({ total: costAmountCad, taxCode });
}
