import { add, allocate } from "dinero.js";
import { Dinero, dineroCADZero, multiplyScaled } from "utils/dinero-util";

import { PurchaseTaxCode } from "./vendor";

export type TaxCode = PurchaseTaxCode;

/**
 * This is the scale to always use when performing multiplications for taxes.
 * This scale is chosen based on the maximum number of decimal places that we expect to see in tax rates.
 */
export const TaxRateScale = 4;

/**
 * This class represents a breakdown of different tax rates applied to a cost.
 */
export class TaxBreakdown {
  /** Static instance for a cost with no tax codes applied */
  public static EMPTY = new TaxBreakdown(new Map());

  /** The sum of all the taxes in the `taxes` map */
  public readonly total: Dinero;

  /**
   * Create a new instance of TaxBreakdown
   * @param taxes All the tax codes applied to the cost, and the amount of tax applied for each tax code.
   */
  constructor(public readonly taxes: Map<TaxCode, Dinero>) {
    this.total = Array.from(taxes.values()).reduce((acc, tax) => add(acc, tax), dineroCADZero);
  }

  public add(other: TaxBreakdown): TaxBreakdown {
    const newTaxes = new Map(this.taxes);
    for (const [taxCode, tax] of other.taxes) {
      const existingTax = newTaxes.get(taxCode);
      newTaxes.set(taxCode, add(existingTax ?? dineroCADZero, tax));
    }
    return new TaxBreakdown(newTaxes);
  }

  public multiply(amount: number): TaxBreakdown {
    const newTaxes = new Map(
      [...this.taxes].map(([taxCode, tax]) => [taxCode, multiplyScaled(tax, { amount, scale: TaxRateScale })]),
    );
    return new TaxBreakdown(newTaxes);
  }

  public allocate(ratios: number[]): TaxBreakdown[] {
    // Allocate each of the taxes based on the ratios provided
    const allocatedTaxes = [...this.taxes.entries()].map(
      ([taxCode, amount]) => [taxCode, allocate(amount, ratios)] as [TaxCode, Dinero[]],
    );

    // Create a new TaxBreakdown for each of the ratios
    return ratios.map(
      (_, i) => new TaxBreakdown(new Map(allocatedTaxes.map(([taxCode, amounts]) => [taxCode, amounts[i]!]))),
    );
  }

  public getLabel() {
    if (this.taxes.size === 0) return "";
    if (this.taxes.size === 1) return Array.from(this.taxes.keys())[0]!.name;
    return "taxes";
  }
}
