import { SupabaseSelectResult } from "lib/supabase";
import { SupportedCurrencyCode, validateCurrencyCode } from "utils/dinero-util";

export type VendorWithPricingInformation = SupabaseSelectResult<
  "Vendor",
  typeof vendorPricingInformationSelect,
  VendorSelectRelatedTables
>;

export type PurchaseTaxCode = VendorWithPricingInformation["taxCode"];

export type VendorSelectRelatedTables = "Currency" | "TaxCode";

export const vendorPricingInformationSelect = `
  id,
  name,
  currency:Currency!currencyId(id,code),
  taxCode:TaxCode!taxCodeId(id,name,purchaseTaxRate)
`;

export function getCurrencyCodeForVendor(vendor: VendorWithPricingInformation): SupportedCurrencyCode {
  const currency = vendor.currency;
  if (!currency) throw new Error(`Vendor '${vendor.name}' has no currency`);
  return validateCurrencyCode(currency.code);
}
