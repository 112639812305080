import { Cell, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Fragment } from "react/jsx-runtime";
import { useState, useCallback } from "react";

import { DataTable } from "components/DataTable";
import { FormatDate } from "components/Date";
import { ErrorSpan } from "components/ErrorSpan";
import { CountingProgressBar } from "components/NumberBasedProgressBar";
import { SosEntityLink } from "components/SosEntityLink";
import { StarNumber } from "types/sos-types";
import { Dinero, formatDineroCad } from "utils/dinero-util";
import { getSosStarEmoji } from "utils/sos/utilities";
import { SalesOrderTrackingData } from "../queries/sales-order-tracking-data";
import { PurchaseOrder, PurchaseOrderLineItem } from "../queries/tracking-data/purchase-orders";
import { getCostTaxBreakdownColumnDefs } from "./CostTaxBreakdownColumns";
import { LineItemDescription } from "./LineItemDescription";
import { withSalesOrderTrackingData } from "./withSalesOrderTrackingData";
import { Form } from "react-bootstrap";

interface TableRow {
  po: PurchaseOrder;
  line: PurchaseOrderLineItem;
}

const columnHelper = createColumnHelper<TableRow>();

// For po columns, set the rowspan of each cell on the on the first line to the total number of lines. Hide all subsequent rows for the same receipt
const poColCellProps = (
  cell: Cell<TableRow, unknown>,
  additionalProps?: Omit<React.TdHTMLAttributes<HTMLTableCellElement>, "rowSpan">,
) => {
  const { po, line } = cell.row.original;
  const firstLineId = po.jobLines[0]!.id;
  const isFirstLine = line.id === firstLineId;
  return isFirstLine ?
      { ...additionalProps, rowSpan: po.jobLines.length }
    : { ...additionalProps, className: "d-none " + (additionalProps?.className ?? "") };
};

const columns: ColumnDef<TableRow>[] = [
  columnHelper.group({
    header: "Purchase Orders",
    columns: [
      columnHelper.accessor("po.number", { header: "PO #", meta: { cellProps: poColCellProps } }),
      columnHelper.accessor("po.date", {
        header: "Date",
        cell: ({ getValue }) => <FormatDate date={getValue()} />,
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor<"po.starred", StarNumber>("po.starred", {
        header: "Star",
        cell: ({ getValue }) => getSosStarEmoji(getValue()),
        meta: { cellProps: (cell) => poColCellProps(cell, { className: "text-center" }) },
      }),
      columnHelper.accessor("po.closed", {
        header: "Closed",
        cell: ({ getValue }) => (getValue() ? "✅" : "⬜"),
        meta: { cellProps: (cell) => poColCellProps(cell, { className: "text-center" }) },
      }),
      columnHelper.accessor<"po.vendor.name", string>("po.vendor.name", {
        header: "Vendor",
        cell: ({ getValue, row: { original } }) => (
          <>
            {getValue()} ({original.po.vendor.currency?.code ?? <ErrorSpan>⚠ No currency set</ErrorSpan>})
          </>
        ),
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor("po.receivedJobLines", {
        header: "Received Lines",
        cell: ({ getValue, row }) => <CountingProgressBar now={getValue()} max={row.original.po.jobLines.length} />,
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor<"po.linkedReceipts", PurchaseOrder["linkedReceipts"]>("po.linkedReceipts", {
        header: "Linked Item Receipts",
        enableSorting: false,
        cell: ({ getValue }) =>
          getValue().map((receipt, index) => (
            <Fragment key={receipt.id}>
              {index > 0 && ", "}
              <SosEntityLink type="itemreceipt" id={receipt.id} label={receipt.number} />
            </Fragment>
          )),
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor("po.currency.code", {
        header: "Currency",
        cell: ({ getValue, row: { original } }) => {
          const poCurrencyCode = getValue();
          const vendorCurrency = original.po.vendor.currency;
          return (
            <>
              {poCurrencyCode}
              {vendorCurrency && poCurrencyCode !== vendorCurrency.code && (
                <ErrorSpan>⚠ Mismatch With Vendor</ErrorSpan>
              )}
            </>
          );
        },
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor("po.exchangeRate", { header: "Exchange Rate", meta: { cellProps: poColCellProps } }),
      columnHelper.accessor("po.jobLinesSummary.categorizedCosts.material.subtotal", {
        header: "Material Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor("po.jobLinesSummary.categorizedCosts.nonMaterial.subtotal", {
        header: "Non-Material Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: poColCellProps },
      }),
      columnHelper.accessor("po.jobLinesSummary.categorizedCosts.shipping.subtotal", {
        header: "Shipping Costs",
        cell: ({ getValue }) => formatDineroCad(getValue()),
        meta: { cellProps: poColCellProps },
      }),
      ...getCostTaxBreakdownColumnDefs<TableRow>((row) => row.po.jobLinesSummary.cost, {
        idPrefix: "po_jobLinesSummary_cost",
        cellProps: poColCellProps,
      }),
    ],
  }),
  columnHelper.group({
    header: "Line Items",
    columns: [
      columnHelper.accessor("line.lineNumber", {
        header: "Line #",
        enableSorting: false,
        meta: {
          headerCellProps: () => ({ className: "border-start-double" }),
          cellProps: () => ({ className: "border-start-double" }),
        },
      }),
      columnHelper.accessor("line.item.name", { header: "Item", enableSorting: false }),
      columnHelper.accessor("line.item.type", { header: "Type", enableSorting: false }),
      columnHelper.accessor("line.item.description", {
        header: "Description",
        enableSorting: false,
        cell: ({ row }) => <LineItemDescription line={row.original.line} />,
      }),
      columnHelper.accessor<"line.received", number>("line.received", {
        header: "Received/Quantity",
        enableSorting: false,
        cell: ({ getValue, row }) => <CountingProgressBar now={getValue()} max={row.original.line.quantity} />,
      }),
      columnHelper.accessor<"line.linkedLineReceipts", PurchaseOrderLineItem["linkedLineReceipts"]>(
        "line.linkedLineReceipts",
        {
          header: "Linked Item Receipts",
          enableSorting: false,
          cell: ({ getValue }) => (
            <>
              {getValue().map((receipt, index) => (
                <Fragment key={receipt.id}>
                  {index > 0 && ", "}
                  <SosEntityLink type="itemreceipt" id={receipt.id} label={receipt.number} />
                </Fragment>
              ))}
            </>
          ),
        },
      ),
      columnHelper.accessor<"line.unitCost.subtotal", Dinero>("line.unitCost.subtotal", {
        header: "Unit Cost",
        enableSorting: false,
        cell: ({ getValue }) => formatDineroCad(getValue()),
      }),
      ...getCostTaxBreakdownColumnDefs<TableRow>((row) => row.line.totalCost, {
        idPrefix: "line_totalCost",
        enableSorting: false,
      }),
    ],
  }),
];

const pricingDetailColumnIds = new Set([
  "po_currency_code",
  "po_exchangeRate",
  "po_jobLinesSummary_categorizedCosts_material_subtotal",
  "po_jobLinesSummary_categorizedCosts_nonMaterial_subtotal",
  "po_jobLinesSummary_categorizedCosts_shipping_subtotal",
  "line_totalCost_tax",
  "line_totalCost_total",
]);
const lineItemColumnIds = new Set([
  "line_lineNumber",
  "line_item_name",
  "line_item_type",
  "line_item_description",
  "line_received",
  "line_linkedLineReceipts",
  "line_unitCost_subtotal",
  "line_totalCost_subtotal",
  "line_totalCost_tax",
  "line_totalCost_total",
]);

const PurchaseOrders: React.FC<SalesOrderTrackingData> = ({ purchaseOrders }) => {
  // Define state
  const [showLineItems, setShowLineItems] = useState(true);
  const [showPricingDetails, setShowPricingDetails] = useState(false);

  // Define callbacks
  const toggleShowLineItems = useCallback(() => {
    setShowLineItems(!showLineItems);
  }, [showLineItems]);
  const toggleShowPricingDetails = useCallback(() => {
    setShowPricingDetails(!showPricingDetails);
  }, [showPricingDetails]);

  // Determine the table column's visibility
  // For each column, it is only visible if ALL the associated filters are set
  const allToggleableColumnIds = new Set([...pricingDetailColumnIds, ...lineItemColumnIds]);
  const columnVisibility = Object.fromEntries(
    [...allToggleableColumnIds].map((columnId) => [
      columnId,
      (!pricingDetailColumnIds.has(columnId) || showPricingDetails) &&
        (!lineItemColumnIds.has(columnId) || showLineItems),
    ]),
  );

  // Flatten the list of item receipts into a list of table rows (one row for each line item and receiving cost)
  const data = purchaseOrders.flatMap((po) => po.jobLines.map<TableRow>((line) => ({ po, line })));

  return (
    <>
      <h2>Purchase Orders</h2>
      <div className="overflow-hidden mb-3">
        <div className="row row-cols-auto g-4 align-items-center">
          <span>Filters:</span>
          <Form.Check type="switch" label="Show Line Items" checked={showLineItems} onChange={toggleShowLineItems} />
          <Form.Check
            type="switch"
            label="Show Pricing Details"
            checked={showPricingDetails}
            onChange={toggleShowPricingDetails}
          />
        </div>
      </div>
      <DataTable
        bordered
        hover
        columns={columns}
        data={data}
        state={{ columnVisibility }}
        initialState={{
          sorting: [{ id: "po_date", desc: true }],
        }}
      />
    </>
  );
};

export default withSalesOrderTrackingData(PurchaseOrders, "Purchase Orders");
