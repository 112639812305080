import { PostgrestFilterBuilder as PostgrestFilterBuilderInternal, UnstableGetResult } from "@supabase/postgrest-js";
import { createClient } from "@supabase/supabase-js";
import { GenericFunction, GenericView } from "@supabase/supabase-js/dist/module/lib/types";

import { Database, Tables } from "types/supabase-types";

export const supabase = createClient<Database>(process.env.SUPABASE_URL, process.env.SUPABASE_ANON_KEY);

export type SupabaseTableName = keyof Database["public"]["Tables"];

/** All the relationships for the specified table. */
export type Relationships<TTableName extends SupabaseTableName> =
  Database["public"]["Tables"][TTableName]["Relationships"];

/**
 * A relationship in the Supabase database.
 *
 * Rather than define this as an interface, it is defined as the intersection of all the auto-generation relationship types
 */
export type Relationship = Relationships<SupabaseTableName>[number];

/**
 * The intersection of all relationship types on the specified table (i.e. that reference other tables).
 */
export type RelationshipFromEntity<TTableName extends SupabaseTableName> = Relationships<TTableName>[number];

/**
 * The intersection of all relationship types on other tables that reference the specified table.
 */
export type RelationshipToEntity<TTableName extends SupabaseTableName> = {
  [ReferenceTableName in SupabaseTableName]: Relationships<ReferenceTableName>[number] & {
    tableName: ReferenceTableName;
    referencedRelation: TTableName;
  };
}[SupabaseTableName];

/**
 * Utility type to get the result of a select query on a table.
 *
 * @param TRelationName The name of the table to select from.
 * @param TQuery The query to use when selecting from the table.
 * @param TTables By default, this type uses the entire schema of the database. For better performance, this parameter can be used to limit the number of database tables used.
 */
export type SupabaseSelectResult<
  TRelationName extends SupabaseTableName,
  TQuery extends string,
  TRelationshipTables extends SupabaseTableName = SupabaseTableName,
> = UnstableGetResult<
  PublicSchemaSubset<TRelationName | TRelationshipTables>,
  Tables<TRelationName>,
  TRelationName,
  Relationships<TRelationName>,
  TQuery
>;

type PostgrestFilterBuilder<
  TRelationName extends SupabaseTableName,
  TSelectQuery extends string = "*",
> = PostgrestFilterBuilderInternal<
  Database["public"],
  Tables<TRelationName>,
  SupabaseSelectResult<TRelationName, TSelectQuery>,
  TRelationName,
  Relationships<TRelationName>
>;

export interface PublicSchemaSubset<TTables extends SupabaseTableName> {
  Tables: Pick<Database["public"]["Tables"], TTables>;
  Views: Record<string, GenericView>;
  Functions: Record<string, GenericFunction>;
}

// NOTE: This is set to the default value in Supabase, but can be changed
const PAGINATION_SIZE = 1000;

/**
 * Fetch all records from a Supabase table, paginating as needed.
 *
 * @param table The name of the table to fetch records from.
 * @param select The select query to use when fetching records. Defaults to all columns (*).
 * @param queryBuilderCallback An optional callback to modify the query builder (i.e. add additional filtering) before executing the query.
 */
export async function supabaseFetchAllPages<TRelationName extends SupabaseTableName, TSelectQuery extends string = "*">(
  table: TRelationName,
  select?: TSelectQuery,
  queryBuilderCallback?: (
    builder: PostgrestFilterBuilder<TRelationName, TSelectQuery>,
  ) => PostgrestFilterBuilder<TRelationName, TSelectQuery>,
): Promise<SupabaseSelectResult<TRelationName, TSelectQuery>[]> {
  // Get the first page; count the total number of records
  queryBuilderCallback ??= (x) => x;
  const firstPageBaseQuery = supabase
    .from(table)
    .select(select, { count: "exact" })
    .range(0, PAGINATION_SIZE - 1) as unknown as PostgrestFilterBuilder<TRelationName, TSelectQuery>;
  const firstPageQuery = queryBuilderCallback(firstPageBaseQuery);
  const firstPageResponse = await firstPageQuery.throwOnError();
  const allData = firstPageResponse.data as SupabaseSelectResult<TRelationName, TSelectQuery>[];

  // Get the total count
  const count = firstPageResponse.count;
  if (count === null)
    throw new Error(
      "Count was not returned in the first page of data. Ensure the 'count' option is set correctly in the select() options",
    );

  // Return early if there are no more pages
  if (count <= PAGINATION_SIZE) return allData;

  // Fetch the remaining pages
  const numPages = Math.ceil(count / PAGINATION_SIZE);
  for (let pageIndex = 1; pageIndex < numPages; pageIndex++) {
    const rangeStart = pageIndex * PAGINATION_SIZE;
    const rangeEnd = rangeStart + PAGINATION_SIZE - 1;
    const pageBaseQuery = supabase
      .from(table)
      .select(select)
      .range(rangeStart, rangeEnd) as unknown as PostgrestFilterBuilder<TRelationName, TSelectQuery>;
    const pageQuery = queryBuilderCallback(pageBaseQuery);
    const pageData = (await pageQuery.throwOnError()).data as SupabaseSelectResult<TRelationName, TSelectQuery>[];
    allData.push(...pageData);
  }

  return allData;
}
