import { useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import { AuthNavStatus } from "components/AuthNavStatus";
import LinkContainer from "components/LinkContainer";
import { Logo } from "components/Logo";
import { ThemeSwitcher } from "components/ThemeSwitcher";
import { SessionContext } from "stores/SessionContext";
import { SyncStatusNavDropDown } from "./SyncStatusNavDropdown";

export const TopNav: React.FC = () => {
  const { session } = useContext(SessionContext);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Logo /> Senti Order Tracker
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {session && (
              <>
                <Nav.Item>
                  <LinkContainer to={`/sales-orders`}>
                    <Nav.Link>Sales Orders</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </>
            )}
          </Nav>
          <Nav className="ms-md-auto">
            <SyncStatusNavDropDown className="me-1" />
            <div className="vr mx-1"></div>
            <ThemeSwitcher className="me-1" />
            <AuthNavStatus />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
