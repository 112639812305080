import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import { supabase } from "lib/supabase";
import { ItemReceipt } from "./item-receipts";
import { createLineSummaryForJob, ProcessedPurchasingLine, PurchaseCostsSummary } from "./purchasing-line-item";
import { PurchaseTaxCode, vendorPricingInformationSelect, VendorWithPricingInformation } from "./vendor";
import { itemSelect, ItemSelectResult } from "./items";
import { Tables } from "types/supabase-types";
import { validateCurrencyCode } from "utils/dinero-util";

export interface PurchaseOrder extends Omit<PurchaseOrderSelectResult, "lines"> {
  receivedJobLines: number;
  linkedReceipts: PurchaseOrderLineItemSelectResult["linkedItemReceiptLineItems"][0]["itemReceipt"][];
  allLines: PurchaseOrderSelectResult["lines"];
  jobLines: PurchaseOrderLineItem[];
  jobLinesSummary: PurchaseCostsSummary;
}

export interface PurchaseOrderLineItem extends ProcessedPurchasingLine<PurchaseOrderLineItemSelectResult> {
  linkedLineReceipts: PurchaseOrderLineItemSelectResult["linkedItemReceiptLineItems"][0]["itemReceipt"][];
}

interface PurchaseOrderSelectResult extends Tables<"PurchaseOrder"> {
  vendor: VendorWithPricingInformation;
  currency: Pick<Tables<"Currency">, "code">;
  lines: PurchaseOrderLineItemSelectResult[];
}

export interface PurchaseOrderLineItemSelectResult
  extends Pick<
    Tables<"PurchaseOrderLineItem">,
    "id" | "lineNumber" | "description" | "unitprice" | "amount" | "quantity" | "received"
  > {
  item: ItemSelectResult;
  taxCode: PurchaseTaxCode | null;
  job: Pick<Tables<"Job">, "id" | "name"> | null;
  linkedItemReceiptLineItems: LinkedItemReceiptLineItem[];
}

interface LinkedItemReceiptLineItem extends Pick<Tables<"ItemReceiptLineItem">, "quantity"> {
  itemReceipt: Pick<Tables<"ItemReceipt">, "id" | "number">;
}

const purchaseOrderSelect = `
*,
vendor:Vendor!inner(${vendorPricingInformationSelect}),
currency:Currency!inner(code),
lines:PurchaseOrderLineItem(
  id,
  lineNumber,
  description,
  unitprice,
  amount,
  quantity,
  received,
  item:Item!inner(${itemSelect}),
  taxCode:TaxCode!taxCodeId(id,name,purchaseTaxRate),
  job:Job!inner(id, name),
  linkedItemReceiptLineItems:ItemReceiptLineItem(
    quantity,
    itemReceipt:ItemReceipt!inner(id, number)
  )
)
`;

export async function getPurchaseOrders(jobId: number, itemReceipts: ItemReceipt[]): Promise<PurchaseOrder[]> {
  // Get all PO ids based on PO line items assigned to the job
  const jobMatchingPOIdsResponse = await supabase
    .from("PurchaseOrderLineItem")
    .select("purchaseOrderId")
    .eq("jobId", jobId)
    .throwOnError();
  const jobMatchingPOIds = jobMatchingPOIdsResponse.data.map((x) => x.purchaseOrderId);

  // Get all PO ids based on linked item receipt line items assigned to the job
  const jobMatchingReceiptLines = itemReceipts.flatMap((x) => x.jobLines);
  const receiptMatchingPoIds = jobMatchingReceiptLines
    .filter((x) => x.linkedPurchaseOrderLineItem)
    .map((x) => x.linkedPurchaseOrderLineItem!.purchaseOrder.id);

  // Get the POs from Supabase
  const allMatchingPOIds = uniq([...jobMatchingPOIds, ...receiptMatchingPoIds]);
  const queryResult = (
    await supabase
      .from("PurchaseOrder")
      .select<typeof purchaseOrderSelect, PurchaseOrderSelectResult>(purchaseOrderSelect)
      .in("id", allMatchingPOIds)
      // .neq("lines.linkedItemReceiptLineItems.quantity", 0) // Do not return linked item receipt line items with zero quantity
      .order("date", { ascending: false })
      .order("lineNumber", { referencedTable: "lines", ascending: true })
      .throwOnError()
  ).data;

  // Do some additional client-side processing on the results before returning
  return queryResult.map((po) => processPo(po, jobId));
}

function processPo({ lines: allLines, ...po }: PurchaseOrderSelectResult, jobId: number): PurchaseOrder {
  // Process the line items; Do some common processing first, then continue doing some additional processing specific to POs
  const currencyCode = validateCurrencyCode(po.currency.code);
  const { jobLines: preProcessedJobLines, jobLinesSummary } = createLineSummaryForJob(
    allLines,
    currencyCode,
    po.exchangeRate,
    jobId,
  );
  const jobLines = preProcessedJobLines.map(processPoLineItem);

  // Get all linked receipts for the PO (for the current job only)
  const linkedReceipts = uniqBy(
    jobLines.flatMap((line) => line.linkedLineReceipts),
    (x) => x.id,
  );

  // Count the number of job line items that have been fully received
  const receivedJobLines = jobLines.filter((x) => x.received === x.quantity).length;

  return { ...po, linkedReceipts, allLines, jobLines, jobLinesSummary, receivedJobLines };
}

function processPoLineItem(line: ProcessedPurchasingLine<PurchaseOrderLineItemSelectResult>): PurchaseOrderLineItem {
  // Get all linked receipts for the current line item
  const linkedLineReceipts = uniqBy(
    line.linkedItemReceiptLineItems.filter((x) => x.quantity).map((x) => x.itemReceipt),
    (x) => x.id,
  );
  return { ...line, linkedLineReceipts };
}
